import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setSignInByApple, setSignInByFacebook, setSignInByGoogle, setSignUpUserByUsernameAndEmail } from "../../../redux/auth/auth.actions";
import Loader from "../../Loader";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { FacebookAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { Appleprovider, auth, Facebookprovider } from "../../../firebase/utils";
import {
  apple_circle_icon,
  facebook_icon,
  user_icon,
  google_icon
} from "../../../constants/images";

function SignUpEmail({ handleSignUpStep }) {
  const isLoading = useSelector((state) => state.auth.emailUpLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUpData, setSignUpData] = useState({
    fullname: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const googleLogin = useGoogleOneTapLogin({
    onSuccess: (response) => {
      dispatch(
        setSignInByGoogle(
          {
            idToken: response.credential,
          },
          navigate
        )
      );
    },
    onError: () => {
      console.error('Login Failed');
    }
  });

  const handleSignUpWithEmail = (e) => {
    e.preventDefault();
    if (
      signUpData.fullname === "" ||
      signUpData.email === "" ||
      signUpData.password === ""
    ) {
      toast.error("Please fill in all fields.");
    } else {
      dispatch(
        setSignUpUserByUsernameAndEmail(
          {
            email: signUpData.email,
            password: signUpData.password,
            username: signUpData.fullname,
          },
          handleSignUpStep,
          navigate
        )
      );
      setSignUpData({
        fullname: "",
        email: "",
        password: "",
      });
    }

    // handleSignUpStep(2);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSignUpData({
      ...signUpData,
      [e.target.name]: e.target.value,
    });
  };

  const loginWithApple = () => {
    signInWithPopup(auth, Appleprovider)
      .then((result) => {
        // The signed-in user info.

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        dispatch(setSignInByApple({ token: accessToken, secret: idToken }));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
      });
  };

  const loginFacebook = () => {
    signInWithPopup(auth, Facebookprovider)
      .then((result) => {
        // The signed-in user info.

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        dispatch(setSignInByFacebook({ idToken: accessToken }));

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {});
  };


  return (
    <div className="flex flex-col w-full items-center gap-6">
      <form className="w-full max-w-[430px] flex flex-col gap-4" onSubmit={handleSignUpWithEmail}>
        <div>
          <label className="font-medium text-sm">Full Name</label>
          <div className="flex w-full h-10 border pr-[18px] border-[#CBD5E0] justify-between rounded-lg items-center">
            <input
              name="fullname"
              className="w-full border-none outline-none h-full px-[22px] rounded-lg"
              value={signUpData.fullname}
              placeholder="e.g Anthony Samuel"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="">
          <label className="font-medium text-sm">Email Address</label>
          <div className="flex w-full h-10 border pr-[18px] border-[#CBD5E0] justify-between rounded-lg items-center">
            <input
              name="email"
              className="w-full border-none outline-none h-full px-[22px] rounded-lg"
              value={signUpData.email}
              placeholder="e.g Anthonysam@gmail.com"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="">
          <label className="font-medium text-sm">Password</label>
          <div className="flex w-full h-10 border pr-[18px] border-[#CBD5E0] justify-between rounded-lg items-center">
            <input
              name="password"
              className="w-full border-none outline-none h-full px-[22px] rounded-lg"
              value={signUpData.password}
              placeholder="Password"
              onChange={(e) => handleChange(e)}
            />
            {showPassword ? (
              <FiEye
                className="cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
                color="#0A0D14"
              />
            ) : (
              <FiEyeOff
                className="cursor-pointer"
                color="#0A0D14"
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
        </div>
        <div className="flex justify-center items-center w-full h-10 rounded-lg text-white bg-[#F04438]">
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="border-none outline-none cursor-pointer"
            >
              Continue
            </button>
          )}
        </div>
      </form>
      <div className="flex gap-4 items-center w-full max-w-[430px]">
        <hr className="bg-[#00003B0D] h-[1px] flex-1" />
        <div className="text-base text-[#718096]">OR</div>
        <hr className="bg-[#00003B0D] h-[1px] flex-1" />
      </div>
      <div className="w-full flex gap-4 flex-col max-w-[430px]">
            <div
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
              onClick={() => handleSignUpStep(0)}
            >
              <img src={user_icon} alt="icon" className="h-4 w-4" />
              <p className="text-[#718096] font-medium">Use Phone Number</p>
            </div>
            {/* facebook */}
            <div
              onClick={loginFacebook}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={facebook_icon} alt="icon" />
              <p className="text-[#718096] font-medium flex justify-center">
                Continue with Facebook
              </p>
            </div>

            {/* Apple */}
            <div
              className="w-[100%] flex items-center h-10  border cursor-pointer border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg
"
            >
              <div
                onClick={loginWithApple}
                className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  rounded-lg"
              >
                <img src={apple_circle_icon} alt="icon" />
                <p className="text-[#718096] font-medium flex justify-center">
                  Continue with Apple
                </p>
              </div>
            </div>
            {/* Google */}
            <div
              onClick={googleLogin}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={google_icon} alt="icon" />
              <p className="text-[#718096] flex justify-center">
                Continue with Google
              </p>
            </div>
          </div>
      </div>
  );
}

export default SignUpEmail;
