import React from "react";
import { useState } from "react";
import { GrLocation } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { setLocation } from "../../redux/posts/posts.action";
import GooglePlace from "../Map/GoglePlaces";

function SelectLocation({ setCurrentLocation, next }) {
  const [address, setAddress] = useState("");
  const [, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const handleLocationChange = (locations, item) => {
    if (locations && item) {
      setDisabled(false);
      dispatch(setLocation(item));
      setCurrentLocation(locations);
      setAddress(item);
      next(60);
    } else {
      setDisabled(true);
    }
  };
  return (
    <div className="w-full h-[340px] overflow-y-scroll relative font-SFProRegular">
      <GooglePlace propsValue={handleLocationChange} />
      {/* <div className="flex items-center p-[12px] rounded-[31px] gap-[10px] border-[#02023414] bg-[#05055806]">
        <CiSearch className="text-[#60646c]" />
        <input
          name="search"
          placeholder="Where are you?"
          className="text-[#828282] bg-transparent  text-[14px] "
        />
      </div> */}
      {address && (
        <div>
          <h4 className="text-[14px] my-2 font-[500] text-[#1C2024]">
            Selected
          </h4>
          <div className="flex items-center gap-[8px]">
            <GrLocation className="bg-[#02023414] rounded-[50%] w-[44px] h-[44px] text-[#1c2024] p-3" />
            <div className="">
              <h4 className="text-black text-[14px] font-[510px]">{address}</h4>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectLocation;
