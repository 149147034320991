import { signInWithPopup } from "firebase/auth";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apple_circle_icon,
  facebook_icon,
  user_icon,
  google_icon,
} from "../../constants/images";
import { Appleprovider, auth, Facebookprovider } from "../../firebase/utils";
import {
  FacebookAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  setSignInByApple,
  setSignInByFacebook,
  setSignInByGoogle,
  setAuthLoading,
} from "../../redux/auth/auth.actions";
import Verification from "../Verification/Verification";
import WithEmail from "../Login/LoginComponent/WithEmail/WithEmail";
import WithUserName from "./withUserName/WithUserName";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import SignUpEmail from "./withEmail/SignUpEmail";
import PhoneInput from "react-phone-input-2";
import Loader from "../Loader";
import { toast } from "react-toastify";

function SignUp({ setShowModal, setShowLogin }) {
  const [signUpStep, setSignUpStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [, setVerificationToken] = useState("");
  const [disable, setDisable] = useState(true);
  const [, setLoginStep] = useState(0);

  const handleroute = () => {
    navigate("/login");
  };

  useGoogleOneTapLogin({
    onSuccess: (response) => {
      dispatch(
        setSignInByGoogle(
          {
            idToken: response.credential,
          },
          navigate
        )
      );
    },
    onError: () => {
      console.error("Login Failed");
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      dispatch(
        setSignInByGoogle(
          {
            idToken: response.credential,
          },
          navigate
        )
      );
    },
  });

  const handleSignUpStep = (id) => {
    setSignUpStep(id);
  };

  const loginWithApple = () => {
    signInWithPopup(auth, Appleprovider)
      .then((result) => {
        // The signed-in user info.

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        dispatch(setSignInByApple({ token: accessToken, secret: idToken }));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        setShowModal(false);
      })
      .catch((error) => {
        // Handle Errors here.
      });
  };

  const loginFacebook = () => {
    signInWithPopup(auth, Facebookprovider)
      .then((result) => {
        // The signed-in user info.

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        dispatch(setSignInByFacebook({ idToken: accessToken }));

        // IdP data available using getAdditionalUserInfo(result)
        // ...
        setShowModal(false);
      })
      .catch((error) => {});
  };

  const handleChange = (e) => {
    setPhoneNumber(e);
    if (!e.match("[0-9]{13}")) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": (error) => {},
        },
        auth
      );
    }
  }

  const handlePhoneLogin = (e) => {
    e.preventDefault();
    onSignup();
  };

  function onSignup() {
    dispatch(setAuthLoading(true));
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + phoneNumber;
    if (appVerifier) {
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setVerificationToken(confirmationResult.verificationId);
          dispatch(setAuthLoading(false));
          setLoginStep(10);
        })
        .catch((error) => {
          toast.error("Error occured");
          dispatch(setAuthLoading(false));
        });
    }
  }

  return (
    <div className="w-full flex relative flex-col justify-center items-center px-8">
      <div className=" gap-10 mb-10 flex items-center justify-between  max-w-[430px]">
        <h4 className=" text-center text-kdark3 text-[24px]">
          {signUpStep === 4 ? "Login in to Aradugbo" : "Sign up for Aradugbo"}
        </h4>
      </div>
      {signUpStep === 0 && (
        <div className="flex flex-col gap-6 w-full justify-center items-center">
          <form
            className="w-full max-w-[430px] flex flex-col gap-6"
            onSubmit={handlePhoneLogin}
          >
            <div className="flex flex-col gap-1">
              <p className="text-base text-black">Phone Number</p>
              <PhoneInput
                inputStyle={{ maxHeight: "40px" }}
                className="w-full phone-input rounded-md"
                country={"ng"}
                label="Phone"
                value={phoneNumber}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                onChange={handleChange}
              />
            </div>
            <div
              className={`flex justify-center items-center w-full h-10 rounded-lg   ${
                disable
                  ? "bg-gray-200 text-[#525050]"
                  : "bg-[#F04438] text-white"
              }`}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <button
                  type="submit"
                  disabled={disable}
                  className="w-full h-full border-none outline-none"
                >
                  Continue
                </button>
              )}
            </div>
          </form>

          <div className="flex gap-4 items-center w-full max-w-[430px]">
            <hr className="bg-[#00003B0D] h-[1px] flex-1" />
            <div className="text-base text-[#718096]">OR</div>
            <hr className="bg-[#00003B0D] h-[1px] flex-1" />
          </div>

          <div className="w-full flex gap-4 flex-col max-w-[430px]">
            <div
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
              onClick={() => handleSignUpStep(1)}
            >
              <img src={user_icon} alt="icon" className="h-4 w-4" />
              <p className="text-[#718096]">Use email / username</p>
            </div>
            {/* facebook */}
            <div
              onClick={loginFacebook}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <div>
                <img src={facebook_icon} alt="icon" />
              </div>
              <p className="text-[#718096] flex justify-center">
                Continue with Facebook
              </p>
            </div>

            {/* Apple */}
            <div
              onClick={loginWithApple}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={apple_circle_icon} alt="icon" />
              <p className=" text-[#718096] flex justify-center">
                Continue with Apple
              </p>
            </div>
            {/* Google */}
            <div
              onClick={googleLogin}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={google_icon} alt="icon" />
              <p className="text-[#718096] flex justify-center">
                Continue with Google
              </p>
            </div>
          </div>
        </div>
      )}
      {signUpStep === 1 && <SignUpEmail handleSignUpStep={handleSignUpStep} />}
      {signUpStep === 10 && (
        <Verification handleSignUpStep={handleSignUpStep} signup={true} />
      )}
      {signUpStep === 3 && <WithUserName />}
      {signUpStep === 4 && <WithEmail />}
      {signUpStep !== 3 && (
        <p className="text-kbody6 text-center max-w-[430px] my-4 text-[#718096]">
          By continuing, you agree to Aradugbo’s{" "}
          <a href="/legal/privacy-policy" className="text-[#27272E]">
            Terms of Service
          </a>{" "}
          and confirm that you have read the{" "}
          <a href="/legal/privacy-policy">Privacy Policy</a> .
        </p>
      )}
      <div
        className="flex text-[#718096] right-auto left-auto justify-center mt-[5px] cursor-pointer text-[16px]"
        onClick={setShowModal ? () => setShowLogin(true) : () => handleroute()}
      >
        <p className="text-left w-full">
          {" "}
          Have an account? <span className="text-[#F04438]"> Sign in</span>
        </p>
      </div>
    </div>
  );
}

export default SignUp;
