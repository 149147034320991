import { AiTwotoneEdit } from "react-icons/ai";
import { getRemainingDate } from "../../utils/utils";

import moment from "moment/moment";

function CommentCard({
  data,
  index,
  userData,
  showNestedComment,
  nestedComment,
  onReply,
  replyId,
  getAllNestedComments,
  showReplyBox,
  handleReplyComment,
  setReplyMessage,
  replyMessage,
  handleOpenEditComment,
}) {
  return (
    <div className={`w-full flex mb-[12px] px-[37px] `} key={index}>
      <div className="flex">
        <div className="">
          <div className="mr-2">
            <img
              alt="user"
              className="w-[40px] h-[40px] rounded-[50%]"
              src={data.userImage}
            />
          </div>
        </div>
        <div className="">
          <div className="flex items-center">
            <h4 className="mr-[10px] text-[#1C2024] font-normal capitalize">
              {data?.displayName}
            </h4>
            <div> </div>
            <div className="text-[12px] text-[#718096]">
              {moment(data.createdAt).format(" Do MMM YY, h:mm a")}
            </div>
          </div>
          {/* comment message */}
          <div className="text-[13px] text-[#425466]">{data.text}</div>
          <div className="flex items-center ">
            <p
              className="cursor-pointer hover:bg-[#f2f2f2] text-[12px] p-[5px]"
              onClick={() => showReplyBox(true, data.commentId)}
            >
              Reply
            </p>
          </div>
          {/* {showNestedComment && ( */}

          <button
            className="text-[.7rem]"
            onClick={() => getAllNestedComments(data.commentId)}
          >
            --View More replies
          </button>

          {showNestedComment &&
            nestedComment?.map(
              (item) =>
                item.commentId === data?.commentId && (
                  <div className="flex">
                    <div className="mr-2 w-[40px] h-[40px] rounded-[50%]">
                      <img
                        alt="user"
                        className="w-full h-full rounded-[50%]"
                        src={item?.userImage}
                      />
                    </div>
                    <div className="">
                      <div className="flex items-center">
                        <div className="mr-[10px]">{item?.username}</div>
                        <div> </div>
                        <div className="text-[12px] text-[#718096]">
                          {getRemainingDate(item?.createdAt)}
                        </div>
                      </div>
                      {/* comment message */}
                      <div className="text-[13px] text-[#425466]">
                        {item?.text}
                      </div>
                      <div className="flex items-center ">
                        <p
                          className="cursor-pointer text-[.7rem] hover:bg-[#f2f2f2] p-[px]"
                          onClick={() => showReplyBox(true, item?.commentId)}
                        >
                          Reply
                        </p>
                        <div className="mx-2">.</div>
                        {/* <div className="text-[#7d7d7e] text-[12px]">
                          {nestedComment?.length} Reply
                        </div> */}
                      </div>
                      <button
                        className="text-[.7rem]"
                        onClick={() => getAllNestedComments(item.commentId)}
                      >
                        --View More replies
                      </button>
                    </div>
                  </div>
                )
            )}
          {onReply && replyId === data.commentId && (
            <div className="w-full flex bg-[#F7F7F7] mt-2 rounded-[31px] px-2">
              <div className="w-full h-[44px] ">
                <input
                  className="w-full h-full bg-transparent border-none outline-none px-2"
                  value={replyMessage}
                  placeholder="Reply...."
                  onChange={(e) => setReplyMessage(e.target.value)}
                />
              </div>
              <button
                className="text-[#FCBE5A] text-[.8rem]"
                onClick={() =>
                  handleReplyComment(
                    data.commentId,
                    data.username,
                    data.displayName
                  )
                }
              >
                Send
              </button>
            </div>
          )}
        </div>
      </div>
      {/* icon */}
      {userData.user_id === data.userId && (
        <div className="ml-2" onClick={() => handleOpenEditComment(data)}>
          <AiTwotoneEdit />
        </div>
      )}
    </div>
  );
}
export default CommentCard;
