import axios from "axios";
import React, { useEffect, useState } from "react";

import { setCategoryType } from "../../redux/posts/posts.action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { RiAlarmWarningLine } from "react-icons/ri";

function SelectCategory({ token, next }) {
  const [emergencyCategories, setEmergencyCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const activeCategory = useSelector((state) => state.posts.activeCategory);
  const [, setSelectedItem] = useState(activeCategory);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/incident/categories",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);

        setEmergencyCategories(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleEmergencyType = (type) => {
    setSelectedItem(type);
    dispatch(setCategoryType(type));
  };

  return (
    <div className="w-full h-[410px] relative font-SFProRegular">
      <div className="h-[340px] overflow-y-scroll ">
        <h4 className="text-[16px] my-2 font-[500] text-[#1C2024]">
          What is the category of your post?
        </h4>
        <div className="my-2">
          <div className="">
            <p className="text-[14px] text-[#60646c]">
              Your post will show up in Feed, on your profile and in search
              results. This category selection will ensure the right set of
              users see your post and benefit from it
            </p>
            <div className="my-2">
              {loading ? (
                <div className="text-center mx-auto">
                  <Loader big={true} />
                </div>
              ) : (
                <div>
                  {emergencyCategories.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleEmergencyType(item)}
                      className="flex  py-2 items-center border-b-[1px] border-[#0000001A]"
                    >
                      <RiAlarmWarningLine
                        className="w-[44px] h-[44px] rounded-[50%] p-2"
                        style={{
                          background: item?.labelBgColor,
                          color: item?.iconColor,
                        }}
                      />
                      <div className="flex justify-between w-full">
                        <div className="ml-2 my-">
                          <h4 className=" text-[14px] mt- font-[510]">
                            {item?.title}
                          </h4>
                          <p className=" mt- text-[12px] text-[#60646c] font-medium">
                            {item?.description}
                          </p>
                        </div>
                        <label className="flex flex-col items-center cursor-pointer">
                          <input
                            type="radio"
                            name="selectedItem"
                            // value={selectedItem}
                            checked={activeCategory?.title === item?.title}
                            onChange={() => handleEmergencyType(item)}
                            className="float-right border-[#0000001A]"
                          />
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => next(60)}
        className="bg-[#f04438] font-[500] rounded-[5px] my-4 w-full py-[.5rem] text-center  text-white"
      >
        NEXT
      </button>
    </div>
  );
}

export default SelectCategory;
