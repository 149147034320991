import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSignInUserByUsernameAndEmail } from "../../../../redux/auth/auth.actions";

import InputField from "../../../InputField";
import Loader from "../../../Loader";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  apple_circle_icon,
  facebook_icon,
  user_icon,
  google_icon
} from "../../../../constants/images";
import {
  setSignInByGoogle,
  setSignInByFacebook,
} from "../../../../redux/auth/auth.actions";
import AppleSignin from "react-apple-signin-auth";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import {
  auth,
  Facebookprovider,
} from "../../../../firebase/utils";

function WithEmail({ handleloginStep }) {
  const isLoading = useSelector((state) => state.auth.emailLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });

  const googleLogin = useGoogleOneTapLogin({
    onSuccess: (response) => {
      dispatch(
        setSignInByGoogle(
          {
            idToken: response.credential,
          },
          navigate
        )
      );
    },
    onError: () => {
      console.error('Login Failed');
    }
  });

  const changeSignInData = (e) => {
    setSignInData({
      ...signInData,
      [e.target.name]: e.target.value,
    });
    if (signInData?.email) {
      if (isValidEmail(signInData.email) === false) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
    if (signInData.password === "" || isValid === false) {
      setIsDisabled(true);
    }
  };

  const loginFacebook = () => {
    signInWithPopup(auth, Facebookprovider)
      .then((result) => {
        // The signed-in user info.
        // const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        dispatch(setSignInByFacebook({ idToken: accessToken }));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        if (error) {
        }
      });
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    if (
      signInData.email === "" ||
      isValidEmail(signInData.email) === false ||
      signInData.password === ""
    ) {
      setIsValid(false);
      toast.error("Please fill in the form");
    } else {
      dispatch(setSignInUserByUsernameAndEmail(signInData, navigate));
    }
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="flex flex-col w-full items-center gap-6">
      <form
        className="w-full max-w-[430px] flex flex-col gap-4"
        onSubmit={handleSignIn}
      >
        <div>
          <InputField
            label={"Email Address"}
            name="email"
            value={signInData.email}
            placeholder="e.g Anthonysam@gmail.com"
            changeValue={(e) => changeSignInData(e)}
          />
          {!isValid && (
            <span className="mt-[15px] text-[red] text-[14px]">
              Invalid email address
            </span>
          )}
        </div>
        <div className="">
          <label className="font-medium text-sm">Password</label>
          <div className="flex w-full h-10 border pr-[18px] border-[#CBD5E0] justify-between rounded-lg items-center">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              className="w-full h-full border-none outline-none px-[22px] rounded-lg"
              value={signInData.password}
              placeholder="Password"
              onChange={(e) => changeSignInData(e)}
            />
            {showPassword ? (
              <FiEye
                className="cursor-pointer"
                onClick={() => {setShowPassword(!showPassword)         
                      console.log(showPassword)
              }}
                color="#718096"
              />
            ) : (
              <FiEyeOff
                className="cursor-pointer"
                color="#718096"
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
          <div
            className="text-[14px] text-[#a1a3a3] mt-1 cursor-pointer"
            onClick={() => handleloginStep(4)}
          >
            forgot Password?
          </div>
        </div>

        <div
          className={`flex justify-center items-center w-full h-10 cursor-pointer rounded-lg text-white ${
            isDisabled && isValid === false ? "bg-[#cac6c6]" : "bg-[#F04438]"
          }`}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="w-full  h-full"
              disabled={isDisabled && isValid === false ? true : false}
            >
              Continue
            </button>
          )}
        </div>
      </form>
      <div className="flex gap-4 items-center w-full max-w-[430px]">
        <hr className="bg-[#00003B0D] h-[1px] flex-1" />
        <div className="text-base text-[#718096]">OR</div>
        <hr className="bg-[#00003B0D] h-[1px] flex-1" />
      </div>
      {/* other method */}
      <div className="w-full flex gap-4 flex-col max-w-[430px]">
        {/* email/username */}
        <div
          onClick={() => handleloginStep(0)}
          className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
        >
          <img src={user_icon} alt="icon" className="h-4 w-4" />
          <p className="text-[#718096]">Use Phone Number</p>
        </div>
        {/* facebook */}
        <div
          onClick={loginFacebook}
          className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
        >
          <img src={facebook_icon} alt="icon" />
          <p className="text-[#718096] flex justify-center">
            Continue with Facebook
          </p>
        </div>

        {/* Apple */}
        <div
          className="w-[100%] flex items-center h-10  border cursor-pointer border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg
"
        >
          <AppleSignin
            authOptions={{
              clientId: "com.efficion.arad",
              scope: "email name",
              redirectURI: "https://www.myaradugbo.com/login",
              state: "state",
              nonce: "nonce",
              usePopup: true,
            }}
            uiType="light"
            className="apple-auth-btn"
            noDefaultStyle={false}
            onSuccess={(response) => console.log(response)} // default = undefined
            onError={(error) => console.error(error)} // default = undefined
            skipScript={false} // default = undefined
            iconProp={{ style: { marginTop: "10px" } }} // default = undefined
            render={(props) => (
              <div
                className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  rounded-lg"
                {...props}
              >
                <img src={apple_circle_icon} alt="icon" />
                <p className="text-[#718096] flex justify-center">
                  Continue with Apple
                </p>
              </div>
            )}
          />
        </div>
        {/* Google */}
        <div
              onClick={googleLogin}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={google_icon} alt="icon" />
              <p className="text-[#718096] flex justify-center">
                Continue with Google
              </p>
            </div>  
      </div>

    </div>
  );
}

export default WithEmail;
