import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import FixedNav from "../../components/FixedNav/FixedNav";
import Header from "../../components/Header/Header";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import { fetchSinglePost } from "../../redux/posts/posts.action";

const mapState = (state) => ({
  post: state.posts.post,
  darktheme: state.features.settings.darktheme,
});
function SinglePost() {
  const { darktheme } = useSelector(mapState);
  const { id } = useParams();
  const { post } = useSelector(mapState);
  const dispatch = useDispatch();
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);
  useEffect(() => {
    dispatch(fetchSinglePost(id));
  }, [id, dispatch]);

  return (
    <div
      className={`relative max-w-[1920px] overflow-x-hidden min-h-[100vh] ${
        darktheme ? "bg-black text-white" : "bg-white text-[#101828]"
      } `}
    >
      <FixedNav />
      <ThemeButton />
      <div className="max-w-[1240px] m-auto pt-[62px] px-[16px] md:px-[50px]">
        <Header />
        {post && post.length > 0 ? (
          post.map((data) => {
            return (
              <>
                <div className="mt-[100px] text-center">
                  <h6 className="text-kred text-kbody5 font-semibold">
                    Published 20 Jan 2022
                  </h6>
                  <h3 className="font-bold text-kbody4 sm:text-kbody2">
                    {data.title}
                  </h3>
                  <div
                    className={`${
                      darktheme ? "text-kgray3" : "text-#475467"
                    } text-kbody5 mt-6 `}
                  >
                    {data.description}
                  </div>
                  <div className="mt-10 mb-[64px]"></div>
                </div>
                <div className="w-full">
                  <img
                    className="w-full"
                    src={data.urlToImage}
                    alt="single post"
                  />
                </div>
                <div className="w-full">
                  <div className="max-w-[720px] m-auto">
                    <div className="mt-[96px]">
                      <h5 className="font-semibold text-kbody3 mb-5">
                        Introduction
                      </h5>
                      <div
                        className={`text-base ${
                          darktheme ? "text-[#EAECF0]" : "text-[#475467]"
                        } mb-12`}
                      >
                        {data.content}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div>loading...</div>
        )}
      </div>
    </div>
  );
}

export default SinglePost;
