export const postsType = {
  FETCH_EVENTS: "FETCH_EVENTS",
  FETCH_EVENTS_STARTED: "FETCH_EVENTS_STARTED",
  FETCH_EVENTS_FAILED: "FETCH_EVENTS_FAILED",
  FETCH_POSTS: "FETCH_POSTS",
  FETCH_FLAGGED_POSTS_START: "FETCH_FLAGGED_POSTS_START",
  FETCH_FLAGGED_POSTS_SUCCESSFUL: "FETCH_FLAGGED_POSTS",
  FETCH_FLAGGED_POSTS_FAILED: "FETCH_FLAGGED_POSTS",
  VIEW_SINGLE_POST: "VIEW_SINGLE_POST",
  CLOSE_SINGLE_POST: "CLOSE_SINGLE_POST",
  SET_CATEGORY_TYPE_START: "SET_CATEGORY_TYPE_START",
  SET_CATEGORY_TYPE_SUCCESSFUL: "SET_CATEGORY_TYPE_SUCCESSFUL",
  SET_ACCOUNT_TYPE_START: "SET_ACCOUNT_TYPE_START",
  SET_ACCOUNT_TYPE_SUCCESSFUL: "SET_ACCOUNT_TYPE_SUCCESSFUL",
  SET_LOCATION_START: "SET_LOCATION_START",
  SET_LOCATION_SUCCESSFUL: "SET_LOCATION_SUCCESSFUL",
  SET_COMMUNITY_START: "SET_COMMUNITY_START",
  SET_COMMUNITY_SUCCESSFUL: "SET_COMMUNITY_SUCCESSFUL",
  SET_USER_START: "SET_USER_START",
  SET_USER_SUCCESSFUL: "SET_USER_SUCCESSFUL",
  SET_AUDIENCE_START: "SET_AUDIENCE_START",
  SET_AUDIENCE_SUCCESSFUL: "SET_AUDIENCE_SUCCESSFUL",
  FETCH_NOTIFICATON_COUNT_START: "FETCH_NOTIFICATON_COUNT_START",
  FETCH_NOTIFICATON_COUNT: "FETCH_NOTIFICATON_COUNT",
  FETCH_NOTIFICATON_COUNT_ERROR: "FETCH_NOTIFICATON_COUNT_ERROR",
  SET_POSTS: "SET_POSTS",
  FETCH_POST: "FETCH_POST",
  FETCH_COMMUNITIES: "FETCH_COMMUNITIES",
  FETCH_COMMUNITIES_START: "FETCH_COMMUNITIES_START",
  FETCH_COMMUNITIES_ERROR: "FETCH_COMMUNITIES_ERROR",
  SET_POST: "SET_POST",
  FETCH_POSTS_ERROR: "FETCH_POSTS_ERROR",
  FETCH_POST_ERROR: "FETCH_POST_ERROR",
  LIKE_POST: "LIKE_POST",
  LIKE_POST_START: "LIKE_POST_START",
  GET_LIKES_COUNTS: "GET_LIKES_COUNTS",
  LIKE_POST_FAILED: "LIKE_POST_FAILED",
  UPLOAD_POST: "UPLOAD_POST",
  UPLOAD_POST_START: "UPLOAD_POST_START",
  UPLOAD_POST_ERROR: "UPLOAD_POST_ERROR",
  REPORT_POST: "REPORT_POST",
  REPORT_POST_START: "REPORT_POST_START",
  REPORT_POST_ERROR: "REPORT_POST_ERROR",
  SWITCH_POST_COMMENT: "SWITCH_POST_COMMENT",
  SWITCH_POST_COMMENT_START: "SWITCH_POST_COMMENT",
  GET_POST_COMMENT_STATUS: "GET_POST_COMMENT_STATUS",
  GET_POST_COMMENT_STATUS_START: "GET_POST_COMMENT_STATUS_START",
};
