import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apple_circle_icon,
  facebook_icon,
  user_icon,
  google_icon,
} from "../../constants/images";
import Verification from "../Verification/Verification";
import WithEmail from "./LoginComponent/WithEmail/WithEmail";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import {
  setAuthLoading,
  setSignInByFacebook,
  setSignInByGoogle,
} from "../../redux/auth/auth.actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Login.css";
import {
  // Appleprovider,
  auth,
  Facebookprovider,
} from "../../firebase/utils";
import {
  FacebookAuthProvider,
  // OAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import flags from "react-phone-number-input/flags";
import WithUserName from "../SignUp/withUserName/WithUserName";
import Loader from "../Loader";
import EnterEmail from "./ResetPassword/EnterEmail";
import NewPassword from "./ResetPassword/NewPassword";
import AccountModal from "../Modal/AccountModal";
import AppleSignin from "react-apple-signin-auth";
import axios from "axios";

function Login({ setShowModal, setShowLogin }) {
  const phoneLoading = useSelector((state) => state.auth.isLoading);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const [loginStep, setLoginStep] = useState(0);
  const navigate = useNavigate();
  const [country, setCountryCode] = useState(null);

  useGoogleOneTapLogin({
    onSuccess: (response) => {
      dispatch(
        setSignInByGoogle(
          {
            idToken: response.credential,
          },
          navigate
        )
      );
    },
    onError: () => {
      console.error("Login Failed");
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      dispatch(
        setSignInByGoogle(
          {
            idToken: response.credential,
          },
          navigate
        )
      );
    },
    onError: () => {
      console.error("Login Failed");
    },
  });

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": (error) => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    dispatch(setAuthLoading(true));
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + phoneNumber;

    if (appVerifier) {
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setVerificationToken(confirmationResult.verificationId);
          dispatch(setAuthLoading(false));

          setLoginStep(1);
        })
        .catch((error) => {
          console.log(error?.response);
          dispatch(setAuthLoading(false));
        });
    }
  }

  const handleroute = () => {
    navigate("/signup");
  };

  const handleloginStep = (id) => {
    setLoginStep(id);
  };

  const handlePhoneLogin = (e) => {
    e.preventDefault();

    onSignup();
  };

  // const loginWithApple = () => {
  //   signInWithPopup(auth, Appleprovider)
  //     .then((result) => {
  //       console.log(result);
  //       // The signed-in user info.
  //       // const user = result.user;

  //       // Apple credential
  //       const credential = OAuthProvider.credentialFromResult(result);
  //       const accessToken = credential.accessToken;
  //       const idToken = credential.idToken;
  //       dispatch(setSignInByApple({ token: accessToken, secret: idToken }));
  //       // IdP data available using getAdditionalUserInfo(result)
  //                   setShowModal(false)
  //     })
  //     .catch((error) => {
  //       // Handle Errors here.
  //       if (error) {
  //         console.log(error.response);
  //       }
  //       // ...
  //     });
  // };

  const loginFacebook = () => {
    signInWithPopup(auth, Facebookprovider)
      .then((result) => {
        // The signed-in user info.
        // const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        dispatch(setSignInByFacebook({ idToken: accessToken }));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        setShowModal(false);
      })
      .catch((error) => {
        // Handle Errors here.
        if (error) {
        }
      });
  };

  const handleChange = (value) => {
    setPhoneNumber(value);
    if (value?.length >= 11) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get(
        `https://ipinfo.io/json?token=efece21c2df820`
      );
      if (res) {
        setCountryCode((res?.data?.country).toLowerCase());
      }
    } catch (err) {
      console.log(err?.response);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-full flex relative flex-col justify-center items-center my-8 md:my-0 px-8">
      <div className=" gap-10 mb-10 flex items-center justify-between  max-w-[430px]">
        <div className="align-center text-center">
          {loginStep === 1 && (
            ""
          )}
          {(loginStep === 0 || loginStep === 2) && (
            <h4 className=" text-center text-kdark3 text-xl md:text-2xl">
              Welcome back to Arad
            </h4>
          )}
          {(loginStep === 4 || loginStep === 5) && (
            <h4 className=" text-center text-kdark3 text-xl md:text-2xl">
              Reset Password
            </h4>
          )}
        </div>
      </div>
      {loginStep === 0 && (
        <div className="flex flex-col gap-6 w-full justify-center items-center">
          {country && (
            <form
              className="w-full max-w-[430px] flex flex-col gap-6"
              onSubmit={handlePhoneLogin}
            >
              <div className="flex flex-col gap-1">
                <p className="text-base text-black">Phone Number</p>
                <PhoneInput
                  inputStyle={{ maxHeight: "40px" }}
                  className="w-full max-h-10 rounded-md"
                  country={country}
                  label="Phone"
                  value={phoneNumber}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  onChange={handleChange}
                  flags={flags}
                />
              </div>

              <div
                className={`flex justify-center items-center w-full h-10 rounded-lg   ${
                  disable
                    ? "bg-gray-200 text-[#525050]"
                    : "bg-[#F04438] text-white"
                }`}
              >
                {phoneLoading ? (
                  <Loader />
                ) : (
                  <button
                    type="submit"
                    disabled={disable}
                    className="w-full h-10 border-none outline-none"
                  >
                    Continue
                  </button>
                )}
              </div>
            </form>
          )}
          <div className="flex gap-4 items-center w-full max-w-[430px]">
            <hr className="bg-[#00003B0D] h-[1px] flex-1" />
            <div className="text-base text-[#718096]">OR</div>
            <hr className="bg-[#00003B0D] h-[1px] flex-1" />
          </div>
          {/* other method */}
          <div className="w-full flex gap-4 flex-col max-w-[430px]">
            {/* email/username */}
            <div
              onClick={() => handleloginStep(2)}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={user_icon} alt="icon" className="h-4 w-4" />
              <p className="text-[#718096]">Use email / username</p>
            </div>
            {/* facebook */}
            <div
              onClick={loginFacebook}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={facebook_icon} alt="icon" />
              <p className="text-[#718096] flex justify-center">
                Continue with Facebook
              </p>
            </div>

            {/* Apple */}
            <div
              className="w-[100%] flex items-center h-10  border cursor-pointer border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg
"
            >
              <AppleSignin
                authOptions={{
                  clientId: "com.efficion.arad",
                  scope: "email name",
                  redirectURI: "https://www.myaradugbo.com/login",
                  state: "state",
                  nonce: "nonce",
                  usePopup: true,
                }}
                uiType="light"
                className="apple-auth-btn"
                noDefaultStyle={false}
                onSuccess={(response) => console.log(response)} // default = undefined
                onError={(error) => console.error(error)} // default = undefined
                skipScript={false} // default = undefined
                iconProp={{ style: { marginTop: "10px" } }} // default = undefined
                render={(props) => (
                  <div
                    className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  rounded-lg"
                    {...props}
                  >
                    <img src={apple_circle_icon} alt="icon" />
                    <p className="text-[#718096] flex justify-center">
                      Continue with Apple
                    </p>
                  </div>
                )}
              />
            </div>
            {/* Google */}
            <div
              onClick={googleLogin}
              className="w-full flex gap-4 items-center justify-center cursor-pointer  h-10  border border-[#02023414] bg-white hover:bg-[#05055806] rounded-lg"
            >
              <img src={google_icon} alt="icon" />
              <p className="text-[#718096] flex justify-center">
                Continue with Google
              </p>
            </div>
          </div>
        </div>
      )}
      {loginStep === 1 && (
        <Verification
          auth={auth}
          phoneNumber={phoneNumber}
          handleloginStep={handleloginStep}
          verificationToken={verificationToken}
        />
      )}
      {loginStep === 2 && <WithEmail handleloginStep={handleloginStep} />}
      {loginStep === 3 && <WithUserName handleloginStep={handleloginStep} />}
      {loginStep === 4 && <EnterEmail handleloginStep={handleloginStep} />}
      {loginStep === 5 && <NewPassword />}
      {loginStep === 5 && <AccountModal />}
      <div
        className={`flex text-[#718096] w-full justify-center cursor-pointer mt-12 text-base ${
          loginStep === 1 && " "
        }`}
        onClick={setShowModal ? () => setShowLogin(false) : () => handleroute()}
      >
        <p className="text-center w-full">
          {" "}
          Don't have an account?{" "}
          <span className="text-[#F04438]"> Sign up</span>
        </p>
      </div>
    </div>
  );
}

export default Login;
