import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { allCommunities } from "../../redux/api";
import { setSelectedCommunity } from "../../redux/posts/posts.action";
import Loader from "../Loader";

function SelectComm({ next }) {
  const dispatch = useDispatch();
  const [communities, setCommunity] = useState([]);
  const [, setLoadingComm] = useState(false);
  const [newDoc, setNewDoc] = useState("");
  const { communityLoading } = useSelector((state) => state.posts);

  useEffect(() => {
    getCommunities();
    // eslint-disable-next-line
  }, []);

  const getCommunities = useCallback(
    async () => {
      try {
        setLoadingComm(true);
        const { data } = await allCommunities(newDoc);
        if (data) {
          setNewDoc(data?.data?.lastDoc);
          setCommunity(data?.data?.communities);
          setLoadingComm(false);
          // setLoadingMore(true);
        }
      } catch (error) {
        setLoadingComm(false);
      }
      // setIndex((prevIndex) => prevIndex + 1);
    },
    // eslint-disable-next-line
    [newDoc]
  );

  const select = (item) => {
    dispatch(setSelectedCommunity(item));
    next(60);
  };

  return (
    <div className="w-full  h-[430px] overflow-y-scroll font-SFProRegular">
      <div className="flex items-center p-[12px] rounded-[31px] gap-[10px] border-[#02023414] bg-[#05055806]">
        <CiSearch className="text-[#60646c]" />
        <input
          name="search"
          placeholder="Community?"
          className="text-[#828282] bg-transparent w-full outline-none text-[14px] "
        />
      </div>
      {communityLoading ? (
        <div className="mx-auto text-center my-3">
          <Loader big={true} />
        </div>
      ) : (
        <div className="font-SFProRegular">
          <h4 className="my-3 text-[#1C2024]">Communities</h4>

          {communities &&
            communities?.map((item, i) => (
              <div
                key={i}
                onClick={() => select(item)}
                className="flex cursor-pointer py-2 items-center border-b-[1px] border-[#0000001A]"
              >
                <div className="flex w-full items-center">
                  <div className="bg-black w-[40px] h-[40px] rounded-[10px]">
                    {item?.communityCoverPhoto && (
                      <img
                        src={item?.communityCoverPhoto}
                        alt={item?.communityName}
                        className="w-full h-full object-cover rounded-[10px]"
                      />
                    )}
                  </div>
                  <div className="flex ">
                    <div className="ml-2 ">
                      <h4 className=" text-[16px] mt- font-[410]">
                        {item?.communityName}
                      </h4>
                      <p className=" mt- text-[12px] text-[#60646c] font-medium">
                        {item?.memberCount} Members
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SelectComm;
