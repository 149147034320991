import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { aradugbo_icon } from "../../constants/images";
import Logo from "../../assets/images/logo.png";

import { publicLink } from "../../constants/data";
import { useNavigate } from "react-router-dom";
import { FaRegPlusSquare } from "react-icons/fa";
import {
  FiLogOut,
  FiMap,
  FiMessageSquare,
  FiMoon,
  FiSettings,
  FiUser,
} from "react-icons/fi";
import SocketManager from "../../socket/socketManager";
import {
  logOut,
  setModalStatus,
  setSearchBar,
} from "../../redux/auth/auth.actions";
import { fetchAllNotification } from "../../redux/posts/posts.action";
import EditProfile from "../AccountComponent/EditProfile";
import { useDisclosure } from "@chakra-ui/react";
import ThemeToggle from "../ThemeButton/Toggle";
import { HiMenuAlt1 } from "react-icons/hi";
import  LoginModal  from "../Modal/LoginModal";

const DashboardMenu = ({ pathname, location }) => {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const { accessToken, user } = useSelector((state) => state.auth);
  const showSearchBar = useSelector(
    (state) => state.auth.leftSideBar?.showSearchBar
  );
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal]= useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDropDown = () => {
    setOpen(!open);
  };
  const handleLogout = () => {
    dispatch(logOut());
    navigate("/dashboard/home");
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const goTo = (path, name) => {
    if (name === "search" || name === "alert") {
      dispatch(setSearchBar(true, name));
      navigate(`/dashboard/${name}`);
    } else {
      dispatch(setSearchBar(false));

      navigate(path);
    }
  };

  const closeLeftSideBar = () => {
    dispatch(setSearchBar(false, null));
  };

  const handleModalUplaodAction = () => {
    closeLeftSideBar();
    onOpen();
    goTo("/dashboard/home");
    dispatch(setModalStatus(60));
  };

  useEffect(() => {
    SocketManager?.instance?.emitListLiveStream({
      lat: location?.coords?.latitude,
      lon: location?.coords?.longitude,
    });
    SocketManager?.instance?.listenListLiveStream((data) => {
      setData(data);
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchAllNotification());

    // eslint-disable-next-line
  }, []);

  const Menu = forwardRef(function Menu() {
    return (
      <ul
        ref={menuRef}
        tabIndex="0"
        className={`fixed z-[1000] bottom-[90px] x menu w-[200px]  py-2 mx-auto shadow ${
          darktheme ? "bg-black text-white" : "bg-[#fff] text-kdark3"
        }`}
      >
        <li
          className={`px-2 py-[.5rem] `}
          onClick={() => goTo("/dashboard/my-profile", "profile")}
        >
          <span className="flex  gap-[4px] hover:text-red-500 ">
            <FiUser className="w-6 h-4 " />
            <span className="mr-4">View Profile</span>
          </span>
        </li>
        <li
          className="px-2 py-[.5rem]"
          onClick={() => goTo("/dashboard/account", "account")}
        >
          <span className="flex  gap-[4px] hover:text-red-500">
            <FiSettings className="w-6 h-4 " />
            <p className="mr-4">Setting</p>
          </span>
        </li>
        <li
          className="px-2 py-[.5rem]"
          onClick={() => goTo("/dashboard/account", "account")}
        >
          <span className="flex gap-[4px] hover:text-red-500 ">
            <FiMessageSquare className="w-6 h-4 " />
            <p className="mr-4">Feedback and help</p>
          </span>
        </li>
        <li className="px-2 py-[.5rem]">
          <span className="flex  gap-[4px] hover:text-red-500 ">
            <FiMoon className="w-6 h-4 " />
            <p className="mr-4">Dark mode</p>

            <ThemeToggle />
          </span>
        </li>
        <li
          className="px-2 py-[.8rem] border-t-[#ebe9e9] border-t-[1px]"
          onClick={handleLogout}
        >
          <span className="flex hover:text-red-500  ">
            <FiLogOut className="w-6 h-4 " />
            <p className="mr-4">Logout</p>
          </span>
        </li>
      </ul>
    );
  });

  // Close the menu if a click happens outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    // menuRef.current.focus();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`py-6 flex flex-col justify-between h-full relative overflow-y-auto w-full z-[99] ${
          darktheme ? "bg-kdark3" : "bg-[#fff]"
        }`}
      >
        <div>
          <div
            className={`flex items-center  h-20  ${
              showSearchBar ? "justify-center" : "ml-6 p-3"
            }`}
          >
            <a
              href="/dashboard/home"
              className="flex justify-center items-center "
            >
              {showSearchBar ? (
                <img src={Logo} alt="Arad" className="h-6 w-6" />
              ) : (
                <img src={aradugbo_icon} alt="Arad" className="max-h-6 w-20" />
              )}
            </a>
          </div>

          <div
            className={`${
              showSearchBar ? "flex flex-col items-center w-full" : ""
            }`}
          >
            <div
              className={`flex flex-col gap-2  ${
                showSearchBar ? "" : "lg:ml-6 p-3"
              }`}
            >
              {publicLink && (
                <>
                  {publicLink?.map((item) => (
                    <div
                      key={item?.title}
                      // href={path}
                      onClick={() => goTo(item?.path, item.name)}
                      className={`md:w-full gap-2 flex items-center  ${
                        showSearchBar ? "justify-center" : ""
                      }  h-9 group hover:cursor-pointer   ${
                        darktheme
                          ? "bg-kdark3 text-[#888a8b]"
                          : "bg-[#fff] text-[#1C2024]"
                      }  ${
                        pathname?.includes(item?.path)
                          ? " text-[#FC5059]"
                          : " text-[#60646C]"
                      } 
                    ${
                      item?.privateRoute && !accessToken
                        ? "hidden"
                        : "inline-flex"
                    }
                    `}
                    >
                      {item?.title === "Profile" ? (
                        <span>
                          <img
                            src={user?.imageUrl}
                            className="w-6 h-6 rounded-[50%]"
                            alt="user"
                          />
                        </span>
                      ) : (
                        <span className="stroke-[#FC5059] group-hover:text-[#FC5059]">{item?.icon}</span>
                      )}
                      {!showSearchBar && (
                        <>
                          <p
                            className={` group-hover:text-[#FC5059] text-base font-medium   ${
                              pathname?.includes(item?.path)
                                ? " text-[#FC5059] "
                                : "text-[#1C2024]"
                            } ${
                              darktheme ? " text-[#888a8b]" : " text-[#1C2024]"
                            } `}
                          >
                            {item?.title}
                          </p>
                          {item?.title === "Livestream" ? (
                            <span className="bg-red-400 text-white w-4 h-4 text-[.6rem] ml-2 items-center p-1 flex justify-center text-center rounded-full">
                              {data?.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
              {accessToken ? (
                <div
                  className={`text-[white] flex items-center justify-center cursor-pointer gap-2  bg-black border ${
                    showSearchBar ? "w-9 " : "w-[85%]"
                  } rounded-md px-[.rem] py-[.4rem]`}
                  onClick={handleModalUplaodAction}
                >
                  <FaRegPlusSquare
                    className={`text-white ${showSearchBar && "w-[9px]"}`}
                  />
                  {!showSearchBar && (
                    <p className=" text-[white] text-center  justify-center   items-center flex">
                      Upload
                    </p>
                  )}
                </div>
              ) : (
                <div
                  className={`text-[white] flex items-center justify-center cursor-pointer gap-2  bg-black border ${
                    showSearchBar ? "w-9 " : "w-[85%]"
                  } rounded-md px-[.rem] py-[.4rem]`}
                  onClick={() => setShowModal(true)}
                >
                  {!showSearchBar && (
                    <p className=" text-[white] text-center  justify-center items-center flex">
                      Login
                    </p>
                  )}
                </div>
              )}
            </div>

          </div>
        </div>

        {accessToken ? (
          <div className={`flex flex-col gap-2  ${
            showSearchBar ? "" : "lg:ml-6 p-3"
          }`}>
          <div
            className={`md:w-full gap-2 flex items-center  ${
              showSearchBar ? "justify-center" : ""
            }  h-9 hover:text-[#FC5059] hover:cursor-pointer   ${
              darktheme
                ? "bg-kdark3 text-[#888a8b]"
                : "bg-[#fff] text-[#1C2024]"
            } 
          `}
          >
            <span className="stroke-[#FC5059]">
              <FiMap className="h-5 w-5" />
            </span>
            {!showSearchBar && (
              <>
                <p
                  className={` hover:text-[#FC5059] text-base font-medium  ${
                    darktheme ? " text-[#888a8b]" : " text-[#1C2024]"
                  } `}
                >
                  Traffic Maps
                </p>
              </>
            )}
          </div>
          <div
          className={`md:w-full gap-2 flex items-center  ${
            showSearchBar ? "justify-center" : ""
          }  h-9 hover:text-[#FC5059] hover:cursor-pointer   ${
            darktheme
              ? "bg-kdark3 text-[#888a8b]"
              : "bg-[#fff] text-[#1C2024]"
          } 
        `}
        >
          <span className="stroke-[#FC5059]">
            <HiMenuAlt1 className="h-5 w-5" /> 

          </span>
          {!showSearchBar && (
            <>
              <p
                className={` hover:text-[#FC5059] text-base font-medium  ${
                  darktheme ? " text-[#888a8b]" : " text-[#1C2024]"
                } `}
              >
                More
              </p>
            </>
          )}
        </div>
        </div>
        ) : (
          ""
        )}
      </div>
      {<EditProfile open={isOpen} type={"My"} onClose={onClose} />}
      {showModal && <LoginModal isOpen={showModal} setShowModal={setShowModal} />}
    </>
  );
};

export default DashboardMenu;
