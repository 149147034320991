import React, { useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/auth/auth.actions";
import { setSelectedUser } from "../../redux/posts/posts.action";
import Loader from "../Loader";

function SelectUser({ next }) {
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const selectuser = (item) => {
    next(60);
    dispatch(setSelectedUser(item));
  };

  return (
    <div className="w-full  h-[430px] overflow-y-scroll relative font-SFProRegular">
      <div className="flex items-center p-[12px] rounded-[31px] gap-[10px] border-[#02023414] bg-[#05055806]">
        <CiSearch className="text-[#60646c]" />
        <input
          name="search"
          placeholder="Where are you?"
          className="text-[#828282] bg-transparent w-full outline-none text-[14px] "
        />
      </div>
      {loading ? (
        <div className="mx-auto text-center my-4">
          <Loader big={true} />
        </div>
      ) : (
        <div className="font-SFProRegular">
          <h4 className="my-2">Users</h4>
          {users &&
            users?.map((item) => (
              <div
                onClick={() => selectuser(item)}
                className="flex cursor-pointer  py-2 items-center border-b-[1px] border-[#0000001A]"
              >
                <div className="flex w-full items-center cursor-pointer">
                  <div className="bg-black w-[40px] h-[40px] rounded-[50%]">
                    {item?.imageUrl && (
                      <img
                        src={item?.imageUrl}
                        alt={item?.username}
                        className="w-full h-full object-cover rounded-[50%]"
                      />
                    )}
                  </div>
                  <div className="flex ">
                    <div className="ml-2 ">
                      <h4 className=" text-[16px] mt- font-[510]">
                        {item?.displayName}
                      </h4>
                      <p className=" mt- text-[12px] text-[#60646c] font-medium">
                        @{item?.username}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SelectUser;
