import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setForgetPasswordAction } from "../../../redux/auth/auth.actions";
import InputField from "../../InputField";
import Loader from "../../Loader";

function EnterEmail() {
  const isLoading = useSelector((state) => state.auth.resetPasswordLoading);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleEmail = async (e) => {
    e.preventDefault();

    if (email.length > 0) {
      dispatch(setForgetPasswordAction(email));
      setEmail("");
    } else {
      toast.error("Please enter a valid email");
    }
  };

  const onChangeValue = (e) => {
    setEmail(e.target.value);
  };
  return (
    <form className="w-[430px] flex flex-col gap-6" onSubmit={handleEmail}>
      <div>
        <InputField
          label={"Email Address"}
          name="email"
          value={email}
          placeholder="e.g Anthony@gmail.com"
          changeValue={onChangeValue}
        />
      </div>
      <div className="flex justify-center items-center w-full h-10 rounded-lg text-white bg-[#F04438]">
        {isLoading ? (
          <Loader />
        ) : (
          <button
            type="submit"
            className="w-full h-full cursor-pointer border-none outline-none"
          >
            Continue
          </button>
        )}
      </div>
    </form>
  );
}

export default EnterEmail;
