/* eslint-disable */
import { useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserPosts } from "../../redux/peoplePost/peoplepost.actions";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import { setIsLoading } from "../../redux/features/features.action";
import {
  allCommunities,
  allCommunityFeeds,
  joinCommunity,
  searchCommunity,
  searchCommunityFeeds,
  searchEvents,
} from "../../redux/api";
import {
  logOut,
  setModalStatus,
  setStatus,
} from "../../redux/auth/auth.actions";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import CommunityCard from "./Cards";
import TitleHeader from "./TitleHeader";
import CreateCommunity from "./CreateCommunity";
import JoinCommunityModal from "./JoinCommunityModal";
import { toast } from "react-toastify";
import CommunityPostCard from "./CommunityPostCard";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";

function Community() {
  const { onOpen, onClose } = useDisclosure();
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.accessToken);
  const { user } = useSelector((state) => state.auth);
  const [page] = useState(1);
  const [, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingComm, setLoadingComm] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [, setTotalPages] = useState(0);
  const [newDoc, setNewDoc] = useState(null);
  const [, setIndex] = useState(2);
  const uploadModalStatus = useSelector((state) => state.auth.showUploadModal);
  const [community, setCommunity] = useState(null);
  const [selectedCommunityId, setSelectedCommunityId] = useState("");
  const [events, setEvents] = useState([]);
  const [feeds, setFeeds] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const { id } = useParams();
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });
  const [searchTerm, setSearchTerm] = useState("");

  console.log(feeds, community);
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/dashboard/home");
    }
  }, []);

  dayjs.extend(RelativeTime);

  useEffect(() => {
    dispatch(getAllUserPosts());
    dispatch(setIsLoading(true));
  }, [token]);

  const handleSearch = async (more = false) => {
    setLoading(false);
    try {
      !more && setLoading(true);
      const { data } = await searchEvents({ page, search });
      if (data) {
        const documents = data?.hits?.map((item) => {
          return {
            ...item.document,
            media: {
              images: item.document["media.images"],
              videos: item.document["media.videos"],
            },
          };
        });
        setEvents(page === 1 ? documents : [...events, ...documents]);
        setTotalPages(Math.ceil(data.found / data.request_params.per_page));
        setLoading(false);
        setLoadingMore(false);
      }
    } catch (error) {
      setLoading(false);    }
  };

  const visibleTodos = useCallback(() => {
    getCommunities(true);
  }, []);

  useEffect(() => {
    visibleTodos();
  }, []);

  const getCommunities = useCallback(
    async () => {
      try {
        setLoadingComm(true);
        const { data } = await allCommunities(newDoc);
        if (data) {
          setNewDoc(data?.data?.lastDoc);
          setCommunity(data?.data?.communities);
          setLoadingComm(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setLoadingComm(false);
      }
      setIndex((prevIndex) => prevIndex + 1);
    },
    [newDoc]
  );

  useEffect(() => {
    if (user.isDeleted) {
      dispatch(setStatus(true));
      dispatch(logOut());
    }
  }, [user]);

  const handleModalCreateCommunity = () => {
    onOpen();
    dispatch(setModalStatus(71));
  };

  const CloseCommunityModal = () => {
    onClose();
    dispatch(setModalStatus(0));
  };

  const memberJoinCommunity = async (code, id) => {
    setSelectedCommunityId(id);
    onOpen();
    dispatch(setModalStatus(72));
    await submit(code);
  };

  const submit = async (id) => {
    try {
      setLoading(true);
      const { data } = await joinCommunity(id);
      if (data) {
        setLoading(false);
        toast.success(data?.message);
        onClose();
        getCommunities();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });

                setTrigger(!trigger);
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

  }, []);

  const getFeeds = useCallback(
    async () => {
      try {
        setisLoading(true);
        const { data } = await allCommunityFeeds(
          currentLocation?.latitude,
          currentLocation?.longitude
        );
        if (data) {
          setFeeds(data?.data?.communities);
          setisLoading(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setisLoading(false);
      }
    },
    []
  );


  const handleSearchFeeds = useCallback(
    async (searchTerm) => {
      setLoading(true);
      try {
        const { data } = await searchCommunityFeeds(searchTerm.trim(), page);
        const reshapedData = data.hits.map((hit) => ({
          category: hit.document.category,
          comment: "on",
          commentCount: hit.document.commentCount,
          community: {
            id: hit.document.communityID,
            communityID: hit.document.communityID,
            communityName: hit.document.community.communityName,
            communityDesc: hit.document.community.communityDesc,
            communityCoverPhoto: hit.document.community.communityCoverPhoto,
          },
          communityID: hit.document.communityID,
          coords: {
            latitude: currentLocation?.latitude,
            longitude: currentLocation?.longitude
          },
          createdAt: hit.document.createdAt,
          createdAt_unix: hit.document.createdAt_unix,
          id: hit.document.id,
          likeCount: hit.document.likeCount,
          likedBy: [],
          media: hit.document.media,
          notify: "on",
          sharedBy: [],
          sharedCount: 0,
          status: hit.document.status,
          text: hit.document.text,
          title: hit.document.title,
          type: hit.document.type,
          userId: hit.document.userId,
          userImage: hit.document.userImage,
          username: hit.document.username,
        }));
          if (data) {
          setFeeds(reshapedData);
          setisLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [currentLocation?.latitude, currentLocation?.longitude, page]
  );

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTerm) {
        handleSearchFeeds(searchTerm);
      } else {
        getFeeds();
      }
    }, 500);

    return () => clearTimeout(delay);
  }, [searchTerm, handleSearchFeeds, getFeeds]);

  const handleSearchCommunity = useCallback(
    async (searchTerm) => {
      setLoading(true);
      try {
        const { data } = await searchCommunity(searchTerm.trim(), page);
        if (data) {
          const transformCommunityData = (data) => {
            return data.hits.map((community) => {
              return {
                banned: community.document.banned,
                communityCode: community.document.communityCode,
                communityCoverPhoto: community.document.communityCoverPhoto,
                communityDesc: community.document.communityDesc,
                communityID: community.document.communityID,
                communityName: community.document.communityName,
                communityType: community.document.communityType,
                createdAt: community.document.createdAt,
                createdBy: community.document.createdBy,
                id: community.document.id,
                isMember: community.document.isMember,
                memberCount: community.document.memberCount,
                requestToJoin: community.document.requestToJoin,
              };
            });
          };
          const searchedCommunity = transformCommunityData(data);
          setNewDoc(data?.data?.lastDoc);
          setCommunity(searchedCommunity);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [page]
  );

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTerm) {
        handleSearchCommunity(searchTerm);
      } else {
        getCommunities();
      }
    }, 500);

    return () => clearTimeout(delay);
  }, [searchTerm, handleSearchCommunity, getCommunities]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const viewTab = (id) => {
    navigate(`/dashboard/community/${id}`);
  };
  return (
    <DashboardLayout>
      <TitleHeader click={handleModalCreateCommunity} />
      <div className="bg-white h-[100vh]">
        <div
          className={`w-full h-auto  ${
            darktheme
              ? "bg-kdark3 text-[#F9FAFB]"
              : "bg-[#fff] text-[text-[#27272E]"
          }`}
        >
          <div className="max-w-[600px] mx-auto  pt-[rem]">
            <div className="flex w-full mb-[2rem]">
              <p
                className={`font-SF-Pro-Regular cursor-pointer p-1 w-[50%] text-center font-[590] text-[14px] text-[#27272e] ${
                  id === "feeds" && "bord-gr "
                }   pb-[.8rem]  `}
                onClick={() => viewTab("feeds")}
              >
                My Feeds
              </p>
              <p
                onClick={() => viewTab("communities")}
                className={`font-SF-Pro-Regular w-[50%] cursor-pointer text-center font-[590] text-[14px] text-[#27272e] ${
                  id === "communities" && "bord-gr "
                }  `}
              >
                My Communities
              </p>
            </div>

            {id === "feeds" && (
              <>
                {isLoading ? (
                  <div className="flex h-[50vh] justify-center items-center">
                    <Loader big={true} />
                  </div>
                )
                : (
                  <div>
                    <div
                      className={`flex items-center w-full h-[50px] mb-[1rem] px-5 ${
                        darktheme ? "bg-[black]" : "bg-[#F9FAFB]"
                      } rounded-[43px]`}
                    >
                      <input
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search feeds"
                        className="w-full h-full bg-transparent rounded-[43px] border-none outline-none"
                      />
                      <CiSearch size={24} onClick={handleSearch} />
                    </div>
                    {feeds?.length === 0 && (
                      <p className="text-[#60646c]">No Feeds found</p>
                    )}
                    {feeds &&
                      feeds?.map((item) => (
                        <CommunityPostCard
                          data={item}
                          join={memberJoinCommunity}
                          coords={currentLocation}
                        />
                      ))}
                  </div>
                )}
              </>
            )}
            {id === "communities" && (
              <>
                {loadingComm ? (
                  <div className="flex h-[50vh] items-center justify-center">
                    <Loader big={true} />
                  </div>
                ) : (
                 
                  <div>
                    <div
                      className={`flex items-center w-full h-[50px] mb-[1rem] px-5 ${
                        darktheme ? "bg-[black]" : "bg-[#F9FAFB]"
                      } rounded-[43px]`}
                    >
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search Community"
                        className="w-full h-full bg-transparent rounded-[43px] border-none outline-none"
                      />
                      <CiSearch size={24} onClick={handleSearch} />
                    </div>
                    {community?.length === 0 && (
                      <p className="text-[#60646c]">No Communities found</p>
                    )}
                    {community &&
                      community?.map((item) => (
                        <CommunityCard data={item} join={submit} />
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {uploadModalStatus === 71 && (
        <CreateCommunity
          open={uploadModalStatus === 71}
          type={"My"}
          onClose={CloseCommunityModal}
          refresh={getCommunities}
        />
      )}
      {uploadModalStatus === 72 && (
        <JoinCommunityModal
          id={selectedCommunityId}
          open={uploadModalStatus === 72}
          type={"My"}
          onClose={CloseCommunityModal}
        />
      )}
    </DashboardLayout>
  );
}

export default Community;
