import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share";
import { copyTextToClipboard } from "../../utils/utils";
import { AiOutlineCopy } from "react-icons/ai";

function SharePost({ isOpen, header, onClose, theme, id, title }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = (data) => {
    copyTextToClipboard(data)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };
  return (
    <div
      className={`w-full ${
        theme ? "bg-kdark3 text-white" : "bg-[#fff] text-kdark3"
      }`}
    >
      <div
        className={`w-full overflow-auto ${
          theme ? "bg-kdark3 text-white" : "bg-[#fff] text-kdark3"
        }`}
        containerProps={{ justifyContent: "center" }}
      >
        <div
          className={`mx-auto  py-[2rem]  ${
            theme ? "bg-kdark3 text-white" : "bg-[#fff] text-kdark3"
          }`}
        >
          <TwitterShareButton
            className="w-full flex mb-[1rem]"
            url={`https://myaradugbo.com/dashboard/events/${id}`}
            quote={title}
            hashtag="#aradugbo"
          >
            <div className="border rounded-[25px]  w-[100%] flex px-[.5rem] py-[.3rem]">
              <TwitterIcon size={24} round className="" />
              <p className="text-center mx-auto">Twitter</p>
            </div>
          </TwitterShareButton>
          <div>
            {" "}
            <FacebookShareButton
              url={`https://myaradugbo.com/dashboard/events/${id}`}
              quote={title}
              hashtag="#aradugbo"
              className="w-full flex  mb-[1rem]"
            >
              <div className="border w-[100%] rounded-[25px] flex px-[.5rem] py-[.3rem]">
                <FacebookIcon size={24} round />
                <p className="text-center mx-auto">Facebook</p>
              </div>
            </FacebookShareButton>
          </div>
          <div>
            {" "}
            <WhatsappShareButton
              url={`https://myaradugbo.com/dashboard/events/${id}`}
              quote={title}
              hashtag="#aradugbo"
              className="w-full flex  mb-[1rem]"
            >
              <div className="border rounded-[25px] w-[100%] flex px-[.5rem] py-[.3rem]">
                <WhatsappIcon size={25} round />
                <p className="text-center mx-auto">Whatsapp</p>
              </div>
            </WhatsappShareButton>
          </div>
          <div
            onClick={() =>
              handleCopyClick(`https://myaradugbo.com/dashboard/events/${id}`)
            }
          >
            {" "}
            <div className="border w-[100%] rounded-[25px] cursor-pointer flex px-[.5rem] py-[.3rem]">
              <AiOutlineCopy size={24} />
              {isCopied ? (
                <span className="text-center mx-auto">Copied</span>
              ) : (
                <p className="text-center mx-auto">Copy</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharePost;
