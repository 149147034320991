import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import { FaArrowCircleLeft, FaTimes } from "react-icons/fa";
import { FaArrowLeft, FaArrowLeftLong } from "react-icons/fa6";

function ModalComponent({ isOpen, previous, onClose, children, title }) {
  return (
    <Modal className="w-[600px] " isOpen={isOpen} onClose={onClose} size={"lg"}>
      <ModalOverlay />
      <ModalContent
        className="  "
        containerProps={{ justifyContent: "center" }}
      >
        <div className="flex mt-3  p-2 relative items-center w-full">
          {previous ? (
            <div
              onClick={onClose}
              className="w-[36px] absolute cursor-pointer  h-[36px] float-left bg-[#ffffff] border-[.1px] rounded-full my-[1rem]  justify-center flex items-center"
            >
              <FaArrowLeft size={12} className="float-left text-[#050558]" />
            </div>
          ) : (
            <div
              onClick={onClose}
              className="w-[36px] absolute cursor-pointer  h-[36px] float-left bg-[#ffffff] border-[.1px] rounded-full my-[1rem]  justify-center flex items-center"
            >
              <FaTimes size={12} className="float-left text-[#050558]" />
            </div>
          )}
          <h4 className="text-center mx-auto">{title}</h4>
        </div>

        <ModalBody className="">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalComponent;
