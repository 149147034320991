import { postsType } from "./posts.type";

const initialState = {
  posts: [],
  events: [],
  post: [],
  error: false,
  loading: false,
  likes: 0,
  counts: 0,
  uploadLoading: false,
  uploadData: {},
  commentSwitch: {},
  switchLoading: false,
  viewLoading: false,
  postId: "",
  modalId: 0,
  flagPostsLoading: false,
  flagPosts: [],
  activeCategory: {},
  activeLocation: "",
  communities: [],
  selectedCommunity: {},
  selectedUser: {},
  selectedAudience: "Anyone",
  communityLoading: false,
  locationLoading: false,
  searchedCommunity: [],
  lastId: "",
  lastDocId: "",
  more: false,
};

export const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case postsType.FETCH_EVENTS_STARTED:
      return {
        ...state,
        posts: action.payload,
        more: false,
      };
    case postsType.FETCH_EVENTS:
      return {
        ...state,
        // events: action.payload?.events,
        events: [...state.events, ...action.payload.events],
        lastDocId: action.payload?.lastDoc,
        more: action.payload?.lastDoc ? true : false,
      };
    case postsType.FETCH_EVENTS_FAILED:
      return {
        ...state,
        error: true,
        more: false,
      };
    case postsType.FETCH_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case postsType.SET_POSTS:
      return {
        ...state,
        post: action.payload,
      };
    case postsType.FETCH_POSTS_ERROR:
      return {
        ...state,
        error: true,
      };
    case postsType.LIKE_POST_START:
      return {
        ...state,
        loading: true,
      };
    case postsType.LIKE_POST:
      return {
        ...state,
        loading: false,

        likes: action.payload,
      };
    case postsType.LIKE_POST_FAILED:
      return {
        ...state,
        loading: false,

        error: true,
      };
    case postsType.FETCH_NOTIFICATON_COUNT_START:
      return {
        ...state,
        loading: true,
      };
    case postsType.FETCH_NOTIFICATON_COUNT:
      return {
        ...state,
        loading: false,

        counts: action.payload,
      };
    case postsType.FETCH_NOTIFICATON_COUNT_ERROR:
      return {
        ...state,
        loading: false,

        error: true,
      };

    case postsType.UPLOAD_POST_START:
      return {
        ...state,
        uploadLoading: true,
      };
    case postsType.UPLOAD_POST:
      return {
        ...state,
        uploadLoading: false,
        events: state.events.concat(action.payload),
        uploadData: action.payload,
      };
    case postsType.UPLOAD_POST_ERROR:
      return {
        ...state,
        uploadLoading: false,
        error: false,
      };
    case postsType.SWITCH_POST_COMMENT_START:
      return {
        ...state,
        switchLoading: true,
        commentSwitch: false,
      };
    case postsType.SWITCH_POST_COMMENT:
      return {
        ...state,
        switchLoading: false,
        commentSwitch: action.payload,
      };
    case postsType.SET_CATEGORY_TYPE_START:
      return {
        ...state,
        categoryLoading: true,
      };
    case postsType.SET_CATEGORY_TYPE_SUCCESSFUL:
      return {
        ...state,
        categoryLoading: false,
        activeCategory: action.payload,
      };
    case postsType.SET_LOCATION_START:
      return {
        ...state,
        locationLoading: true,
      };
    case postsType.SET_LOCATION_SUCCESSFUL:
      return {
        ...state,
        locationLoading: false,
        activeLocation: action.payload,
      };

    case postsType.SET_COMMUNITY_START:
      return {
        ...state,
        locationLoading: true,
      };
    case postsType.SET_COMMUNITY_SUCCESSFUL:
      return {
        ...state,
        locationLoading: false,
        selectedCommunity: action.payload,
      };
    case postsType.GET_POST_COMMENT_STATUS_START:
      return {
        ...state,
        switchLoading: true,
      };
    case postsType.GET_POST_COMMENT_STATUS:
      return {
        ...state,
        switchLoading: false,
        commentSwitch: state.commentSwitch,
      };
    case postsType.SET_AUDIENCE_START:
      return {
        ...state,
        locationLoading: true,
      };
    case postsType.SET_AUDIENCE_SUCCESSFUL:
      return {
        ...state,
        locationLoading: false,
        selectedAudience: action.payload,
      };
    case postsType.SET_USER_START:
      return {
        ...state,
        locationLoading: true,
      };
    case postsType.SET_USER_SUCCESSFUL:
      return {
        ...state,
        locationLoading: false,
        selectedUser: action.payload,
      };
    case postsType.VIEW_SINGLE_POST:
      return {
        ...state,
        viewLoading: false,
        postId: action.payload.postId,
        modalId: action.payload.modalId,
        modalActive: true,
      };

    case postsType.CLOSE_SINGLE_POST:
      return {
        ...state,
        viewLoading: false,
        postId: 0,
        modalId: null,
        modalActive: false,
      };
    case postsType.FETCH_FLAGGED_POSTS_START:
      return {
        flagPostsLoading: true,
      };
    case postsType.FETCH_FLAGGED_POSTS_SUCCESSFUL:
      return {
        flagPostsLoading: false,
        flagPosts: action.payload,
      };
    case postsType.FETCH_FLAGGED_POSTS_FAILED:
      return {
        flagPostsLoading: false,
      };
    case postsType.FETCH_COMMUNITIES_START:
      return {
        communityLoading: true,
      };
    case postsType.FETCH_COMMUNITIES:
      return {
        communityLoading: false,
        communities: action.payload?.communities,
        lastId: action.payload?.lastDocId,
      };
    case postsType.FETCH_COMMUNITIES_ERROR:
      return {
        communityLoading: false,
      };
    default:
      return state;
  }
};
