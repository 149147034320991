import React, { useState, useRef, useEffect } from "react";
import {
  cancel_icon,
  cloud_icon,
  left_arrow_chevron,
} from "../../constants/images";
import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/utils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FaRegTrashCan } from "react-icons/fa6";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  setCategoryType,
  uploadPostByUser,
} from "../../redux/posts/posts.action";
import { HiDownload } from "react-icons/hi";
function UploadEmergencyInfo({ isOpen, onClose, updateModal, getEvent }) {
  const [, setImage] = useState({});
  const [imageArray, setImageArray] = useState([]);
  const [videoArray, setVideoArray] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [percent, setPercent] = useState(0);
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.posts.activeCategory);
  const [uploadDetails, setUploadDetails] = useState({
    media: {
      images: [],
      videos: [],
    },
    title: "",
    coords: {
      latitude: 0,
      longitude: 0,
    },
    text: "",
    category: "",
    label: "",
    icon: "",
  });
  const inputRef = useRef(null);
  const { coords } = useLocation();
  const [trigger, setTrigger] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });
  // const storage = getStorage();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });
                setTrigger(!trigger);
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

    // eslint-disable-next-line
  }, []);

  const handleUploadImage = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const uploadImage = (file) => {
    if (!file) {
      alert("Please choose a file");
    } else {
      const storageRef = ref(storage, `/images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              if (url) {
                setImageUrl([...imageUrl, url]);
              }
            })
            .catch((err) => console.log(err));
        }
      );
    }
  };

  const uploadVideo = async (file) => {
    let newArray = [];
    if (!file) {
      alert("Please choose a file");
    } else {
      const storageRef = ref(storage, `/videos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              if (url) {
                newArray.push(url);
                setVideoUrl([...videoUrl, url]);
              }
            })
            .catch((err) => console.log(err));
        }
      );
      // });
    }
  };

  const handleUploadData = () => {
    var i;
    for (i = 0; i < imageArray.length; i++) {
      uploadImage(imageArray[i]);
    }
    for (i = 0; i < videoArray.length; i++) {
      uploadVideo(videoArray[i]);
    }
  };

  const handleSubmit = () => {
    let media = {
      images: imageUrl,
      videos: videoUrl,
    };
    const payload = {
      ...uploadDetails,
      coords:
        currentLocation.latitude !== null
          ? {
              latitude: currentLocation.latitude,
              longitude: currentLocation.longitude,
            }
          : coords,
      media,
      type: "regular",
      mapUrl: "",
      platform: "",
      category: activeCategory,
      label: "",
      icons: "",
    };
    dispatch(uploadPostByUser(payload));
    dispatch(setCategoryType(""));
  };

  useEffect(() => {
    if (percent === 100) {
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [imageUrl, videoUrl]);

  const handleFileChange = (e) => {
    if (e.target.files[0]?.type.includes("video")) {
      setVideoArray([...videoArray, e.target.files[0]]);
    } else {
      setImage(e.target.files[0]);
      setImageArray([...imageArray, e.target.files[0]]);
    }
  };

  const deleteImage = (indexToDelete) => {
    const newArr = imageArray.splice(indexToDelete, 1);
    setImageArray(newArr);
  };

  const deleteVideo = () => {};

  const goBack = () => {
    updateModal(6);
  };

  const handleChange = (e) => {
    setUploadDetails({ ...uploadDetails, [e.target.name]: e.target.value });
    if (
      uploadDetails?.title === "" &&
      uploadDetails?.text === "" &&
      imageArray?.length === 0
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const closeModal = () => {
    dispatch(setCategoryType(""));

    setVideoArray([]);
    setImageArray([]);
    setUploadDetails({
      media: {
        images: [],
        videos: [],
      },
      title: "",
      coords: {
        latitude: 0,
        longitude: 0,
      },
      text: "",
      category: "",
      label: "",
      icon: "",
    });
    getEvent();
    setPercent(0);
    onClose();
  };
  return (
    <>
      {percent < 1 ? (
        <Modal
          className="w-full "
          isOpen={isOpen}
          onClose={onClose}
          size={percent < 1 ? "4xl" : "sm"}
        >
          <ModalOverlay />

          <ModalContent
            className="w-full   overflow-y-scroll h-[700px] "
            containerProps={{ justifyContent: "center" }}
          >
            <div className="max-w-[918px]  min-h-[600px] p-[1rem] z-[99999999999] text-black bg-white">
              <div className="relative">
                <div
                  className="absolute cursor-pointer left-0  w-[2rem] h-[2rem] "
                  onClick={goBack}
                >
                  <img
                    src={left_arrow_chevron}
                    alt="Arrow icon"
                    className="w-full h-full"
                  />
                </div>
                <div
                  className="absolute right-0 w-[2rem] h-[2rem] cursor-pointer"
                  onClick={closeModal}
                >
                  <img
                    src={cancel_icon}
                    alt="Cancel icon"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="flex justify-center pt-[15px] pb-[6px] text-black font-semibold text-[24px]">
                Upload Emergency
              </div>

              <form className=" w-full h-[500px]">
                <p
                  className="mb-[.5rem] text-[#60646C];
"
                >
                  Provide incident details.
                </p>
                <div className="w-[100%]  border-[2px] py-[1.5rem] border-dashed border-[#D0D5DD]">
                  <div className="w-full h-full flex flex-col  justify-center items-center">
                    <div>
                      <img src={cloud_icon} alt="Arrow icon" />
                    </div>
                    <div>
                      <h4>Choose a file or drag and drop it here.</h4>
                      <p>JPEG, PNG and MP4 formats up to 50MB.</p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                      accept="image/*, video/*  mp4/* "
                      ref={inputRef}
                    />

                    <button
                      onClick={(e) => handleUploadImage(e)}
                      type="button"
                      className="w-[165px] h-[42px] flex justify-center items-center text-[#D0D5DD] mt-[26px] text-kbody6 border-[#D0D5DD] border-[1px] rounded-[5px]"
                    >
                      Browse File
                    </button>
                  </div>
                </div>
                <div className="flex mt-[1rem]">
                  {imageArray !== 0 &&
                    imageArray?.map((item, i) => (
                      <div
                        key={i}
                        className=" mr-[.5rem] w-[60px] h-[60px] rounded-[10px]"
                      >
                        {item && (
                          <div
                            className="relative h-full w-full"
                            onClick={() => deleteImage(i)}
                          >
                            <FaRegTrashCan className="text-[white] cursor-pointer  absolute  w-30 z-30 right-1 top-1 h-3" />
                            <img
                              src={URL.createObjectURL(item)}
                              alt="uploaded post files"
                              className="object-cover w-full h-full absolute"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  {videoArray !== 0 &&
                    videoArray.map((item, i) => (
                      <div
                        key={i}
                        className="bg-black mr-[.5rem] w-[60px] h-[60px] rounded-[10px]"
                      >
                        {item && (
                          <div
                            className="relative h-full w-full "
                            onClick={deleteVideo}
                          >
                            <FaRegTrashCan className="text-[white] absolute  w-30 z-30 right-1 top-1 h-3" />
                            <video
                              controls={false}
                              className="w-full h-full object-cover absolute"
                            >
                              <source
                                src={URL.createObjectURL(item)}
                                // type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="w-[100%] mt-5 pb-[1rem]">
                  <div className="mt-[4px]">
                    <div className="mb-[10px]">Emergency Type</div>
                    <div className=" flex items-center">
                      <button className="rounded-[25px] bg-[#fdc7c7] text-[red] px-[2rem] py-[.5rem]">
                        {activeCategory}
                      </button>
                      <p onClick={goBack} className="ml-4 text-[#60646C]">
                        Change
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-[10px] mt-5">What’s happening?</div>
                    <div className="w-full h-[54px] border border-[#CBD5E0] pl-5 mb-[25px]">
                      <input
                        className="w-full h-full border-none outline-none"
                        placeholder="Title of emergency"
                        name="title"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-[10px]">Additional Information</div>
                    <textarea
                      className="w-full outline-none h-[172px] p-5  border border-[#CBD5E0] rounded-[4px]"
                      maxLength="100px"
                      // minLength={100}
                      name="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="Title of emergency"
                    ></textarea>
                  </div>
                  <button
                    className={`${
                      isDisabled === true
                        ? "bg-[#D0D5DD]"
                        : "bg-[#F04438] text-white"
                    } w-[100%] rounded-[10px] h-[50px]  flex justify-center items-center my-[1.5rem]`}
                    onClick={handleUploadData}
                    disabled={isDisabled}
                    type="button"
                  >
                    Post
                  </button>
                </div>
              </form>
            </div>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          className="w-full bg-transparent "
          isOpen={isOpen}
          onClose={onClose}
          size={"sm"}
        >
          <ModalOverlay />

          <ModalContent className="bg-transparent p-[1rem] pb-[2rem] mt-[13rem]">
            <div className="relative bg-[red]">
              <div
                className="absolute right-0 w-[2rem] h-[2rem]  cursor-pointer"
                onClick={closeModal}
              >
                <img
                  src={cancel_icon}
                  alt="Cancel icon"
                  className="w-full h-full"
                />
              </div>
            </div>
            <HiDownload className="text-[green] w-12 h-12 bg-[#F9FAFB] p-4 rounded-[50%] mx-auto mt-[1.5rem]" />
            <div className="bg-white px-[2rem] text-center py-[1rem] ">
              <h5 className="">Uploading your Post</h5>
              <p className="mb-[1rem]">
                This is just going to take few seconds
              </p>
              <ProgressBar
                completed={percent}
                bgColor="green"
                height={"20px"}
                className=" text-center"
              />
            </div>
          </ModalContent>
        </Modal>
      )}
      {/* {suspended && ( */}
    </>
  );
}

export default UploadEmergencyInfo;
