import React from "react";
import { BiGlobe } from "react-icons/bi";
import { LiaUserPlusSolid } from "react-icons/lia";
import { useDispatch } from "react-redux";
import { setSelectedAudience } from "../../redux/posts/posts.action";

function PostAudience({ next, active }) {
  const dispatch = useDispatch();

  const selectAudience = (modal, item) => {
    dispatch(setSelectedAudience(item));
    next(modal);
    console.log(item);
  };
  console.log(active);
  return (
    <div className="w-full  h-[530px] overflow-y-scroll relative font-SFProRegular">
      <div className=" font-SFProRegular ">
        <h4 className="text-[16px] my-2 font-[500] text-[#1C2024]">
          Who can see your post?
        </h4>
        <div className="my-2">
          <div className="">
            <p className="text-[14px] text-[#60646c]">
              Your post will show up in Feed, on your profile and in search
              results. Your default audience is set to Friends, but you can
              change the audience of this specific post.
            </p>
          </div>
          <div className="">
            <div className="flex  py-2 items-center border-b-[1px] border-[#0000001A]">
              <div className="flex justify-between w-full mt-4">
                <div className="flex ">
                  <BiGlobe className="text-[#1A202C] bg-[#c4FFC3] h-[44px] rounded-[50%] p-2 w-[44px]" />
                  <div className="ml-2 ">
                    <h4 className=" text-[14px] mt- font-[510]">Anyone</h4>
                    <p className=" mt- text-[12px] text-[#60646c] font-medium">
                      Share your alert with everyone on arad, let everyone know
                      about your post.
                    </p>
                  </div>
                </div>
                <label className="flex flex-col items-center cursor-pointer">
                  <input
                    type="radio"
                    name="selectedItem"
                    checked={active === "Anyone"}
                    onClick={() => selectAudience(60, "Anyone")}
                    className="float-right border-[#0000001A] cursor-pointer"
                  />
                </label>
              </div>
            </div>

            <div className="flex  py-2 items-center border-b-[1px] border-[#0000001A]">
              <div className="flex justify-between w-full mt-4">
                <div className="flex ">
                  <LiaUserPlusSolid className="text-[#431FAE] bg-[#DBCFFF] text-center h-[44px] rounded-[50%] p-2  w-[44px]" />
                  <div className="ml-2 ">
                    <h4 className=" text-[14px] mt- font-[510]">
                      User account
                    </h4>
                    <p className=" mt- text-[12px] text-[#60646c] font-medium">
                      Share your emergency directly with a user on arad.
                    </p>
                  </div>
                </div>
                <label className="flex flex-col items-center cursor-pointer">
                  <input
                    type="radio"
                    name="userAccount"
                    checked={active === "UserAccount"}
                    onClick={() => selectAudience(65, "UserAccount")}
                    className="float-right border-[#0000001A] cursor-pointer"
                  />
                </label>
              </div>
            </div>
            <div className="flex  py-2 items-center border-b-[1px] border-[#0000001A]">
              <div className="flex justify-between w-full mt-4">
                <div className="flex ">
                  <BiGlobe className="text-[#116A81] bg-[#D2F4FD] h-[44px] rounded-[50%] p-2 w-[44px]" />
                  <div className="ml-2 ">
                    <h4 className=" text-[14px] mt- font-[510]">Community</h4>
                    <p className=" mt- text-[12px] text-[#60646c] font-medium">
                      Share posts to communities you're in.
                    </p>
                  </div>
                </div>

                <label className="flex flex-col items-center cursor-pointer">
                  <input
                    type="radio"
                    name="community"
                    // value={index}
                    checked={active === "Community"}
                    onClick={() => selectAudience(64, "Community")}
                    className="float-right border-[#0000001A] cursor-pointer"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute mx-auto w-full  bottom-0">
        <button
          onClick={() => next(64)}
          className="bg-[#f04438] font-[500] rounded-[5px] my-4 w-full py-[.5rem] text-center  text-white"
        >
          NEXT
        </button>
      </div>
    </div>
  );
}

export default PostAudience;
