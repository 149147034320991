import React, { useState } from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import Login from "../Login/Login";
import SignUp from "../SignUp/SignUp";

const LoginModal = ({ isOpen, setShowModal }) => {
  const [showLogin, setShowLogin] = useState(true);
  return (
    <Modal
      className="w-full"
      size={"lg"}
      isOpen={isOpen}
      onClose={() => setShowModal(false)}
      onOverlayClick={() => setShowModal(false)}
    >
      <ModalOverlay />
      <ModalContent
        className="overflow-auto max-w-[500px]"
        containerProps={{ justifyContent: "center", alignItems: "center" }}
        borderRadius={20}
      >
        <ModalBody className="my-8">
          {showLogin ? (
            <Login setShowModal={setShowModal} setShowLogin={setShowLogin} />
          ) : (
            <SignUp setShowModal={setShowModal} setShowLogin={setShowLogin} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
