import React from "react";
import { useLocation } from "react-router-dom";
import Login from "../../components/Login/Login";
import SignUp from "../../components/SignUp/SignUp";
import bg from "../../assets/images/login.mp4";
import { useDispatch, useSelector } from "react-redux";
import AccountModal from "../../components/Modal/AccountModal";
import { setStatus, logOut } from "../../redux/auth/auth.actions";

function Auth() {
  const userStatus = useSelector((state) => state.auth.isDeleted);
  const location = useLocation();
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setStatus(false));
    dispatch(logOut());
  };

  return (
    <div className="flex w-full md:h-[100vh]">
      <div className="hidden lg:w-[50%] xxl:w-[calc(100%-720px)] relative lg:flex items-end  text-center m-2 rounded-full">
        <h4 className="text-7xl font-bold absolute leading-[4.5rem] ml-10 mb-[20%] text-left w-[608px] text-white">
          Protect yourself, <br /> the people <br /> and places <br /> you care
          about.
        </h4>
        {bg && (
          <div className="w-full h-full">
            <video
              className="h-full object-cover rounded-2xl"
              loop
              muted
              playsInline
              autoPlay={true}
            >
              <source src={bg} type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
            </video>
          </div>
        )}
      </div>

      <div className="flex w-full lg:w-[50%] xxl:w-[720px] justify-center items-center md:h-screen">
        {location.pathname === "/login" && <Login />}
        {location.pathname === "/signup" && <SignUp />}
      </div>
      {userStatus && (
        <AccountModal
          title={"Your account has been deleted."}
          description="Figma ipsum component variant main layer. Flows star follower
       figma shadow connection polygon flows. Effect figjam ellipse union
       draft bullet figjam plugin star. Horizontal draft scrolling."
          isOpen={userStatus}
          onClose={closeModal}
        />
      )}
    </div>
  );
}

export default Auth;
