import { io } from "socket.io-client";
import { SOCKET_IO_SERVER } from "./config";
import { store } from "../redux/store";

const EVENT_JOIN_ROOM = "join-room";
const EVENT_LEAVE_ROOM = "leave-room";
const EVENT_LIST_LIVE_STREAM = "list-live-stream";
const EVENT_BEGIN_LIVE_STREAM = "begin-live-stream";
const EVENT_FINISH_LIVE_STREAM = "finish-live-stream";
const EVENT_SEND_MESSAGE = "send-message";
const EVENT_ASK_QUESTION = "ask-question";
const EVENT_LIVE_STREAM_INFO = "room-info";
const EVENT_LIVE_STREAM_OPERATION = "toggle-operation";
const EVENT_PREPARE_LIVE_STREAM = "prepare-live-stream";
const EVENT_KICK_USER = "kick-user";
const EVENT_TIMING = "timer-for-liveStream";
const EVENT_STREAM_DOWN = "bring-down-stream";
const EVENT_ADVERT = "send-advert";

class SocketManager {
  socket = null;
  token = null;

  constructor(token) {
    this.token = token;

    if (SocketManager.instance) {
      return SocketManager.instance;
    }
    SocketManager.instance = this;
    this.socket = io(SOCKET_IO_SERVER, {
      pingTimeout: 30000,
      autoConnect: true,
      auth: { token: this.token },
    });

    const setupListenDefaultEvents = () => {
      this.socket.on("connect", () => {});
      // this.socket.on("disconnect", () => {});
    };
    setupListenDefaultEvents();
    return this;
  }

  //
  // ──────────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I S T E N   E V E N T : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────────
  //

  listenPrepareLiveStream(callback = () => null) {
    this.socket.on(EVENT_PREPARE_LIVE_STREAM, () => {
      return callback();
    });
  }

  listenBeginLiveStream(callback = () => null) {
    this.socket.on(EVENT_BEGIN_LIVE_STREAM, (data) => {
      return callback(data);
    });
  }

  listenFinishLiveStream(callback = () => null) {
    this.socket.on(EVENT_FINISH_LIVE_STREAM, (data) => {
      return callback(data);
    });
  }

  listenListLiveStream(callback = () => null) {
    this.socket.on(EVENT_LIST_LIVE_STREAM, (data) => {
      return callback(data);
    });
  }

  listenStreamInfo(callback = () => null) {
    this.socket.on(EVENT_LIVE_STREAM_INFO, (data) => {
      return callback(data);
    });
  }

  listenSendMessage(callback = () => null) {
    this.socket.on(EVENT_SEND_MESSAGE, (data) => {
      return callback(data);
    });
  }

  listenAskQuestion(callback = () => null) {
    this.socket.on(EVENT_ASK_QUESTION, (data) => {
      return callback(data);
    });
  }

  listenRemoveUser(callback = () => null) {
    this.socket.on(EVENT_KICK_USER, (data) => {
      return callback(data);
    });
  }

  listenTimer(callback = () => null) {
    this.socket.on(EVENT_TIMING, (data) => {
      return callback(data);
    });
  }

  listenStreamDown(callback = () => null) {
    this.socket.on(EVENT_STREAM_DOWN, (data) => {
      return callback(data);
    });
  }

  listenSentAdvert(callback = () => null) {
    this.socket.on(EVENT_ADVERT, (data) => {
      return callback(data);
    });
  }

  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: E M I T   E V E N T : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //

  emitPrepareLiveStream({ userName, roomName }) {
    this.socket.emit(EVENT_PREPARE_LIVE_STREAM, { userName, roomName });
  }

  emitJoinRoom({ user, roomId }) {
    this.socket.emit(EVENT_JOIN_ROOM, { user, roomId });
  }

  emitLeaveRoom({ user, roomId }) {
    this.socket.emit(EVENT_LEAVE_ROOM, { user, roomId });
  }

  emitBeginLiveStream({ title, location, roomId, host }) {
    this.socket.emit(EVENT_BEGIN_LIVE_STREAM, {
      title,
      location,
      roomId,
      host,
    });
  }

  emitFinishLiveStream({ roomId, host }) {
    this.socket.emit(EVENT_FINISH_LIVE_STREAM, { roomId, host });
  }

  emitListLiveStream({ lat, lon }) {
    this.socket.emit(EVENT_LIST_LIVE_STREAM, { lat, lon });
  }

  emitSendMessage({ roomId, user, message }) {
    this.socket.emit(EVENT_SEND_MESSAGE, { roomId, user, message });
  }

  emitAskQuestion({ roomId, user, question }) {
    this.socket.emit(EVENT_ASK_QUESTION, { roomId, user, question });
  }

  emitLiveOperation({ roomId, type, operation }) {
    this.socket.emit(EVENT_LIVE_STREAM_OPERATION, { roomId, type, operation });
  }

  emitKickUser({ user, roomId }) {
    this.socket.emit(EVENT_KICK_USER, { user, roomId });
  }

  emitTimer({ roomId, host }) {
    this.socket.emit(EVENT_TIMING, { roomId, host });
  }

  emitStreamDown({ roomId, host }) {
    this.socket.emit(EVENT_STREAM_DOWN, { roomId, host });
  }

  emitAdvert({ imageUrl, title, description }) {
    this.socket.emit(EVENT_ADVERT, { imageUrl, title, description });
  }
}
store.subscribe(() => {
  const authStore = store.getState();
  const token1 = authStore?.auth?.accessToken;
  if (token1) {
    const instance = new SocketManager(`Bearer ${token1}`);
    Object.freeze(instance);
  }
});

export default SocketManager;
