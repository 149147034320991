import { FaSpinner } from "react-icons/fa";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setCategoryType,
  setLocation,
  setSelectedAudience,
  setSelectedCommunity,
  uploadPostByUser,
} from "../../redux/posts/posts.action";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Modal from "../Modal/Modal";
import { HiMapPin } from "react-icons/hi2";
import GlobeIcon from "../../assets/images/globe.svg";
import Iconly from "../../assets/images/Image.svg";
import Location from "../../assets/images/location.svg";
import Video from "../../assets/images/video-recorder.svg";
import Smile from "../../assets/images/smile.svg";
import { useDisclosure } from "@chakra-ui/react";
import SelectLocation from "./SelectLocationModal";
import SelectCategory from "./SelectCategory";
import PostAudience from "./Audience";
import { RiAlarmWarningLine } from "react-icons/ri";
import SelectUser from "./SelectUser";
import { storage } from "../../firebase/utils";
import { FaRegTrashCan } from "react-icons/fa6";
import Loader from "../Loader";
import SharePost from "./SharePost";
import SelectComm from "./SelectComm";

function UploadEmergency({
  updateModal,
  getEvent,
  commuinityID,
  refreshCommunity,
}) {
  const location = useLocation();

  const [loading] = useState(false);
  const [text, setText] = useState("");
  const [uploadDataLoading, setUploadLoading] = useState(false);
  const { coords } = useLocation();
  const uploadModalStatus = useSelector((state) => state.auth.showUploadModal);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [trigger, setTrigger] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });
  const dispatch = useDispatch();
  const routePath = useLocation();
  const token = useSelector((state) => state.auth.accessToken);
  const {
    uploadLoading,
    uploadData,
    activeCategory,
    activeLocation,
    selectedCommunity,
    selectedAudience,
  } = useSelector((state) => state.posts);
  const darktheme = useSelector((state) => state.features.settings.darktheme);

  const inputRef = useRef(null);
  const [, setImage] = useState({});
  const [imageArray, setImageArray] = useState([]);
  const [videoArray, setVideoArray] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [percent, setPercent] = useState(0);

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [routePath]);

  const next = (item) => {
    onOpen();
    updateModal(item);
  };

  const onHandleChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]?.type.includes("video")) {
      setVideoArray([...videoArray, e.target.files[0]]);
    } else {
      setImage(e.target.files[0]);
      setImageArray([...imageArray, e.target.files[0]]);
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });
                setTrigger(!trigger);
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

    // eslint-disable-next-line
  }, []);

  const closeModal = () => {
    if (location.pathname.includes("/dashboard/community/view")) {
      refreshCommunity();
    } else {
      getEvent();
    }
    dispatch(setCategoryType({}));
    dispatch(setLocation(null));
    onClose();
    updateModal(false);
    dispatch(setCategoryType({}));
    dispatch(setSelectedCommunity({}));
    dispatch(setSelectedAudience(""));
  };

  const handleUploadImage = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    if (percent === 100) {
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [imageUrl, videoUrl]);

  const uploadImage = (file) => {
    if (!file) {
      alert("Please choose a file");
    } else {
      const storageRef = ref(storage, `/images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              if (url) {
                setImageUrl([...imageUrl, url]);
              }
            })
            .catch((err) => console.log(err));
        }
      );
    }
  };

  const uploadVideo = async (file) => {
    let newArray = [];
    if (!file) {
      alert("Please choose a file");
    } else {
      const storageRef = ref(storage, `/videos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              if (url) {
                newArray.push(url);
                setVideoUrl([...videoUrl, url]);
              }
            })
            .catch((err) => console.log(err));
        }
      );
      // });
    }
  };

  const handleUploadData = () => {
    var i;
    setUploadLoading(true);
    for (i = 0; i < imageArray.length; i++) {
      uploadImage(imageArray[i]);
    }
    for (i = 0; i < videoArray.length; i++) {
      uploadVideo(videoArray[i]);
    }
  };

  const handleSubmit = () => {
    const payload = {
      coords:
        currentLocation.latitude !== null
          ? {
              latitude: currentLocation.latitude,
              longitude: currentLocation.longitude,
            }
          : coords,
      title: text,
      platform: "web",
      text: text,
      category: activeCategory,
      media: {
        images: imageUrl,
        videos: videoUrl,
      },
      // type: "regular",
      mapUrl: "",
      label: "",
      icons: "",
      userIds: [],
      communityID: location.pathname.includes("/dashboard/community/view")
        ? commuinityID
        : selectedCommunity?.id,
    };
    dispatch(uploadPostByUser(payload));
    if (uploadLoading === false) {
      setUploadLoading(false);
      next(66);
    }
  };

  const deleteImage = (indexToDelete) => {
    const newArr = imageArray.splice(indexToDelete, 1);
    setImageArray(newArr);
  };

  const deleteVideo = () => {};

  return (
    <>
      {uploadModalStatus === 60 && (
        <Modal
          isOpen={uploadModalStatus === 60}
          onClose={closeModal}
          title="Upload Incident"
          className="rounded-2xl"
        >
          {uploadDataLoading ? (
            <div className="text-center mx-auto">
              <Loader big={true} />
            </div>
          ) : (
            <div className="w-full h-[525px] relative font-SFProRegular">
              <textarea
                className="w-full h-[70px] text-[20px] text-[#718096] outline-none"
                placeholder="What's happening..."
                value={text}
                onChange={(e) => onHandleChange(e)}
              ></textarea>
              <div className="flex my-2 items-center gap-2 font-SFProRegular font-[400]">
                <button
                  onClick={() => next(61)}
                  className="flex gap-[4px] py-[6px]  font-[400] text-[14px]  h-[32px] pr-[16px] pl-[6px] bg-[#00003B0D] rounded-[999px] items-center text-[#586f94]"
                >
                  <HiMapPin className="text-[#425466]" />{" "}
                  {activeLocation ? activeLocation : "Location"}
                </button>
                <button
                  onClick={() => next(62)}
                  className={`flex gap-[4px] font-[400] text-[14px] py-[6px] h-[32px]  pr-[16px] pl-[6px]
                  
                    rounded-[999px] items-center text-[#586f94]`}
                  style={{
                    background: activeCategory?.labelBgColor
                      ? activeCategory?.labelBgColor
                      : "#00003B0D",
                  }}
                  //
                >
                  <RiAlarmWarningLine
                    className="text-[#425466]  "
                    style={{
                      background: activeCategory?.labelBgColor,
                      color: activeCategory?.iconColor,
                    }}
                  />{" "}
                  {activeCategory?.title
                    ? activeCategory?.title
                    : "Incident type"}
                </button>
                {!location.pathname.includes("/dashboard/community/view") && (
                  <button
                    onClick={() => next(63)}
                    className="flex gap-[4px] py-[6px] font-[400] text-[14px]  h-[32px] pr-[16px] pl-[6px] bg-[#00003B0D] rounded-[999px] items-center text-[#1c2024]"
                  >
                    <img
                      src={GlobeIcon}
                      alt="icon"
                      className="text-[#425466]"
                    />
                    {selectedAudience}
                  </button>
                )}
              </div>
              <div className="flex overflow-x-scroll w-full mt-[2rem]">
                {imageArray !== 0 &&
                  imageArray?.map((item, i) => (
                    <div
                      key={i}
                      className=" mr-[.5rem] w-full h-[280px] rounded-[10px]"
                    >
                      {item && (
                        <div
                          className="relative rounded-[10px] h-full w-full"
                          onClick={() => deleteImage(i)}
                        >
                          <FaRegTrashCan className="text-[white] cursor-pointer  absolute  w-30 z-30 right-1 top-1 h-3" />
                          <img
                            src={URL.createObjectURL(item)}
                            alt="uploaded post files"
                            className="object-cover rounded-[10px] w-full h-full absolute"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                {videoArray !== 0 &&
                  videoArray.map((item, i) => (
                    <div
                      key={i}
                      className="bg-black mr-[.5rem] w-[60px] h-[60px] rounded-[10px]"
                    >
                      {item && (
                        <div
                          className="relative h-full w-full "
                          onClick={deleteVideo}
                        >
                          <FaRegTrashCan className="text-[white] absolute  w-30 z-30 right-1 top-1 h-3" />
                          <video
                            controls={false}
                            className="w-full h-full object-cover absolute"
                          >
                            <source
                              src={URL.createObjectURL(item)}
                              // type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div className="absolute bottom-2 w-full">
                <div className="flex items-center justify-between w-full">
                  <div className="flex gap-4">
                    <img src={Smile} alt="icon" />
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                      accept="image/*, video/*  mp4/* "
                      ref={inputRef}
                    />
                    <img
                      src={Video}
                      alt="icon"
                      onClick={(e) => handleUploadImage(e)}
                    />
                    <img
                      src={Iconly}
                      alt="icon"
                      onClick={(e) => handleUploadImage(e)}
                    />
                    <img src={Location} alt="icon" onClick={() => next(61)} />
                  </div>
                  <div>
                    <button
                      onClick={(e) => handleUploadData()}
                      className="bg-[#E5484D] rounded-[12px] px-[20px] py-[12px] font-[400] font-SFProRegular text-[14px] text-white"
                    >
                      {uploadDataLoading ? "Posting..." : "Post"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
      {uploadModalStatus === 61 && (
        <Modal
          isOpen={uploadModalStatus === 61 && isOpen}
          onClose={() => next(60)}
          previous={60}
          title="Select Location"
          className="rounded-2xl"
        >
          {loading ? (
            <FaSpinner className="text-center mx-auto" />
          ) : (
            <SelectLocation
              setCurrentLocation={setCurrentLocation}
              next={next}
            />
          )}
        </Modal>
      )}
      {uploadModalStatus === 62 && (
        <Modal
          isOpen={uploadModalStatus === 62 && isOpen}
          onClose={() => next(60)}
          previous={61}
          title="Incident Category"
          className="rounded-2xl"
        >
          {loading ? (
            <FaSpinner className="text-center mx-auto" />
          ) : (
            <SelectCategory token={token} next={next} />
          )}
        </Modal>
      )}
      {uploadModalStatus === 63 && (
        <Modal
          isOpen={uploadModalStatus === 63 && isOpen}
          onClose={() => next(60)}
          previous={60}
          title="Post Audience"
          className="rounded-2xl"
        >
          {loading ? (
            <FaSpinner className="text-center mx-auto" />
          ) : (
            <PostAudience token={token} active={selectedAudience} next={next} />
          )}
        </Modal>
      )}
      {uploadModalStatus === 64 && (
        <Modal
          isOpen={uploadModalStatus === 64 && isOpen}
          onClose={() => next(60)}
          previous={63}
          title="Select Community"
          className="rounded-2xl"
        >
          {loading ? (
            <FaSpinner className="text-center mx-auto" />
          ) : (
            <SelectComm next={next} />
          )}
        </Modal>
      )}
      {uploadModalStatus === 65 && (
        <Modal
          isOpen={uploadModalStatus === 65 && isOpen}
          onClose={() => next(60)}
          previous={63}
          title="Select User"
          className="rounded-2xl"
        >
          {loading ? (
            <FaSpinner className="text-center mx-auto" />
          ) : (
            <SelectUser next={next} />
          )}
        </Modal>
      )}
      {uploadModalStatus === 66 && (
        <Modal
          isOpen={uploadModalStatus === 66 && isOpen}
          onClose={closeModal}
          title="Post Uploaded Successfully"
          className="rounded-2xl"
        >
          <SharePost
            theme={darktheme}
            next={next}
            id={uploadData?.id}
            header="Post Uploaded Successfully"
            title={uploadData?.title}
          />
        </Modal>
      )}
    </>
  );
}

export default UploadEmergency;
