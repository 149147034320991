import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import LockIcon from "../../assets/images/lock-circle.svg";
import Avatar from "../../assets/images/Avatars.svg";
import Icon from "../../assets/images/Icon.svg";
import { FiUsers } from "react-icons/fi";
import { PiGlobeHemisphereWest } from "react-icons/pi";
import { CiCalendar } from "react-icons/ci";
import { LuLogOut } from "react-icons/lu";
import moment from "moment";
function CommunityInfo({ open, onClose, community, userLeave, invite }) {
  const menuRefs = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRefs.current && !menuRefs.current.contains(event.target)) {
        onClose();
      }
    };

    // menuRefs.current.focus();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Modal
      ref={menuRefs}
      className="w-full "
      isOpen={open}
      onClose={onClose}
      size={"xl"}
    >
      {/* <ModalOverla1y /> */}
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="mt-[1rem] text-center mx-auto   ">
            <h4 className="tex ">Community Settings</h4>
            <div>
              <div className="w-[100px] h-[100px] bg-black mx-auto rounded-[50%]"></div>
              <div className="flex w-full mt-[1rem] justify-center items-center">
                <h4 className="text-base text-black font-[400] mr-[1rem]">
                  {community?.communityName}
                </h4>
                <img src={LockIcon} alt="icon" className="w-[20px] h-[20px]" />
              </div>
              <div className="flex items-center my-[1rem] justify-center">
                <img src={Avatar} alt="user icon" />
                <p className="text-[12px] font-[400] ml-[.5rem]">
                  {" "}
                  {community?.memberCount2} Members
                </p>
              </div>
              <div className="flex gap-4 items-center my-[1rem] justify-center">
                <button
                  onClick={invite}
                  className="border-[1.5px] flex items-center font-[400] text-[#60646c] border-[#EBEBEF] bg-[#F2F2F5] rounded-[25px] px-[1rem] py-[.5rem]"
                >
                  <img src={Icon} className="text-base mr-[.3rem]" /> Invite
                  members
                </button>
                <button
                  onClick={() => userLeave()}
                  className="border-[1.5px] flex items-center font-[400] text-[#E5484D] border-[#E5484D] rounded-[25px] px-[1rem] py-[.5rem]"
                >
                  <LuLogOut className="text-base mr-[.3rem]" /> Leave
                </button>
              </div>
            </div>
          </div>
          <div className="pb-2">
            <h4 className="text-base  text-black font-[600] font-SF-Pro-Regular">
              Community Info
            </h4>
            <p>{community?.communityDesc}</p>
            <div className=" flex items-center my-2 text-[#000000] font-[400]">
              <FiUsers className="text-base mr-[.3rem]" />{" "}
              <p>Only community members can post</p>
            </div>
            <div className=" flex items-center  font-[400] text-[#000000]">
              <PiGlobeHemisphereWest className="text-base mr-[.3rem]" />{" "}
              <p>
                {community?.communityType === "public"
                  ? "Anyone can join this community"
                  : "Private community group"}
              </p>
            </div>
            <div className=" flex items-center my-2 font-[400] text-[#000000]">
              <CiCalendar className="text-base mr-[.3rem]" />{" "}
              <p>
                Created {moment(community?.createdAt).format(" Do MMM YYYY")} by
                @{community?.createdBy?.username}
              </p>
            </div>
            <h4 className="mt-2 mb-2 font-[600]">Rules</h4>
            Keep Posts Relevant
            <div className="flex gap-4">
              <p className="bg-[#0000301B] flex items-center justify-center text-center rounded-[50%] w-[34px] h-[34px] text-[#000000]">
                1
              </p>
              <div>
                <h4>Be Respectful</h4>
                <p>
                  We encourage constructive criticism delivered in a kind an
                  polite way.
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="bg-[#0000301B] flex items-center justify-center text-center rounded-[50%] w-[34px] h-[34px] text-[#000000]">
                2
              </p>
              <div>
                <h4>Keep post relevant</h4>
                <p>
                  We encourage constructive criticism delivered in a kind an
                  polite way.
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="bg-[#0000301B] flex items-center justify-center text-center rounded-[50%] w-[34px] h-[34px] text-[#000000]">
                3
              </p>
              <div>
                <h4>Be Respectful</h4>
                <p>
                  We encourage constructive criticism delivered in a kind an
                  polite way.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CommunityInfo;
