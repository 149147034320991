import axios from "axios";
import { store } from "./store";
import dayjs from "dayjs";
import JWTDecoder from "../utils/utils";
import { authTypes } from "./auth/auth.types";

const http = axios.create({
  baseURL: "https://us-central1-aradugbo-ac17a.cloudfunctions.net/api",
  timeout: 45000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const privateHttp = axios.create({
  baseURL: "https://us-central1-aradugbo-ac17a.cloudfunctions.net/api",
  timeout: 45000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const socketHttp = axios.create({
  baseURL: "https://socket.myaradugbo.com/",
  timeout: 45000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let isRefreshing = false;
let refreshSubscribers = [];

// Notify subscribers after refresh
const onRefreshed = (newToken) => {
  refreshSubscribers.map((callback) => callback(newToken));
  refreshSubscribers = [];
};

const isTokenExpired = async (expirationTime) => {
  const currentTime = Math.floor(Date.now() / 1000);
  if (expirationTime < currentTime) {
    await refreshAccessToken();
    return false;
  }
  return expirationTime < currentTime;
};

// refresh access token
const refreshAccessToken = async () => {
  const isRefreshingToken = store.getState().auth.isRefreshingToken;
  if (isRefreshingToken) return;
  try {
    store.dispatch({ type: authTypes.REFRESH_USER_TOKEN_START });
    const refreshToken = await store.getState().auth.refreshToken;
    const response = await privateHttp.post("auth/refreshToken", {
      refreshToken,
    });
    store.dispatch({
      type: authTypes.UPDATE_ACCESS_TOKEN,
      payload: response.data.token,
    });
    store.dispatch({
      type: authTypes.REFRESH_USER_TOKEN,
      payload: {
        token: response.data.token,
        refreshToken: response.data.refreshToken,
        tokenExpiration: Math.floor(Date.now() / 1000) + 3540,
      },
    });
    return response.data.token;
  } catch (error) {
    console.error(error);
    store.dispatch({
      type: authTypes.REFRESH_USER_TOKEN_FAILED,
      payload: error,
    });
  }
};

privateHttp.interceptors.request.use(async (config) => {
    const token = await store.getState().auth.accessToken;
    const tokenExpiration = await store.getState().auth.tokenExpiration;
    if (token && await isTokenExpired(tokenExpiration)) {
        const newToken = await store.getState().auth.accessToken;
        config.headers.Authorization = `Bearer ${newToken}`;
    } else {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  })

privateHttp.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If token expired
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          refreshSubscribers.push((newToken) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(privateHttp(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = await store.getState().auth?.refreshToken;
        const response = await axios.post("auth/refreshToken", {
          refreshToken,
        });
        if (response) {
          store.dispatch({
            type: authTypes.REFRESH_USER_TOKEN,
            payload: response.data,
          });
        }
        localStorage.setItem("accessToken", response?.data?.token);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
        privateHttp.defaults.headers.common.Authorization = `Bearer ${response?.data?.token}`;
        isRefreshing = false;
        onRefreshed(response?.data?.token);

        return privateHttp(originalRequest);
      } catch (err) {
        isRefreshing = false;
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

socketHttp.interceptors.request.use(
  async (req) => {
    const token = await store.getState().auth.accessToken;

    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
      const user = JWTDecoder.decode(token);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;
      try {
        const refreshToken = await store.getState().auth?.refreshToken;
        const response = await http.post("auth/refreshToken", { refreshToken });
        if (response) {
          store.dispatch({
            type: authTypes.REFRESH_USER_TOKEN,
            payload: response.data,
          });
          localStorage.setItem("accessToken", response?.data?.token);
          localStorage.setItem("refreshToken", response?.data?.refreshToken);
          req.headers.Authorization = `Bearer ${response?.data?.token}`;
        }
      } catch (err) {
        console.error(err?.response);
      }
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//AUTH
export const login = (payload) => http.post("/login", payload);

export const appleLogin = (payload) => http.post("/login/apple-oauth", payload);

export const signUp = (payload) => http.post("/register", payload);

export const googleLogin = (payload) =>
  http.post("/login/google-oauth", payload);

export const loginWithPhone = (payload) =>
  http.post("/login/phone-oauth", payload);

//PEOPLE POSTS
export const getUserPosts = () => http.get(`/events`);

export const getUserFlaggedPosts = () => http.get(`/events/flagged`);

export const getAllUserPost = ({ more, lastDoc }) => {
  return http.get(`/events/${!more ? "null" : lastDoc}/pages`);
};

export const updateProfile = (username, payload) =>
  privateHttp.post(`/user/${username}/update-profile`, payload);

export const updateProfileImage = (payload) =>
  privateHttp.post(`/changePicture`, payload);

export const getUserDetails = () => privateHttp.get(`/users`);

export const getAllUser = () => privateHttp.get(`/users/all`);
export const updateLocation = (payload) =>
  privateHttp.patch(`/user/location`, payload);
export const likeEventById = (eventId, payload) =>
  privateHttp.post(`/events/${eventId}/like`, payload);

export const refresh = (item) => http.post("/auth/refreshToken", item);

export const deleteAccount = (item) => privateHttp.post("/deleteAccount", item);

export const getAllNotifications = ({ more, newDoc }) =>
  privateHttp.get(`/notification/${!more ? "null" : newDoc}`);

export const getAllActivityAction = ({ getIndex, newDoc }) =>
  privateHttp.get(`/alertActivity/${getIndex === 1 ? "null" : newDoc}`);

export const getNotificationsCount = () =>
  privateHttp.get(`/notifications/count`);

export const getLiveRoomDetail = (roomId) => socketHttp.get(`/live/${roomId}`);

export const postMessage = (payload) => socketHttp.post("/message", payload);

export const joinAmbassadorProgram = (payload) =>
  http.post("/ambassador", payload);

export const getMapEvent = (payload) =>
  privateHttp.get(
    `/mapEvent?lat=${payload?.latitude}&lon=${payload?.longitude}`
  );

export const getActivities = (item) =>
  privateHttp.get(
    `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/notification/${item}`
  );

export const getSingleActivities = (eventId) =>
  privateHttp.get(
    `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events/${eventId}`
  );

export const userPost = (username) =>
  privateHttp.get(`/events/client/${username}`);

export const userUploadPost = (payload) => privateHttp.post(`/events`, payload);

export const reportPost = (payload) => privateHttp.post(`/reportPost`, payload);

export const searchEvents = ({ page, search }) =>
  http.get(`/events?page=${page}&q=${search}`);

export const userPostCommentStatus = (eventId, payload) =>
  privateHttp.patch(`event/${eventId}/status`, payload);

export const getAllUserLivestreams = () =>
  privateHttp.get(`/liveStream/${null}/user`);

export const allCommunities = (id) =>
  privateHttp.get(`/communities${id ? `?lastDocId=${id}` : ""}`);

export const allCommunityFeeds = (lat, lon) =>
  privateHttp.get(`/events/community`);

export const communityById = (id) => privateHttp.get(`/communities/${id}`);

export const leaveCommuniti = (id) =>
  privateHttp.post(`/communities/${id}/leave`);

export const joinCommunity = (id) =>
  privateHttp.post(`/communities/${id}/join`);

export const inviteCommunity = (id, data) =>
  privateHttp.post(`/communities/${id}/invite`, data);

export const createACommunity = (data) =>
  privateHttp.post(`/communities`, data);

export const communityPost = (id) =>
  privateHttp.get(`/events/community?communityID=${id}`);

export const searchCommunityFeeds = (searchTerm, page) => privateHttp.get(`events/search/communities?searchTerm=${searchTerm.toLowerCase()}&page=${page}`)
export const searchCommunity = (name, page) =>
  privateHttp.get(`/communities/search?searchTerm=${name}&page=${page}`);
