export const authTypes = {
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  SEND_VERIFICATION_CODE: "SEND_VERIFICATION_CODE",
  CHECK_VERIFICATION_CODE: "CHECK_VERIFICATION_CODE",
  SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_START:
    "SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_START",
  SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_FAILED:
    "SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL_FAILED",
  SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL:
    "SET_SIGN_IN_USER_BY_USERNAME_AND_EMAIL",
  SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL:
    "SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL",
  SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_FAILED:
    "SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_FAILED",
  SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_START:
    "SET_SIGN_UP_USER_BY_USERNAME_AND_EMAIL_START",
  SET_SIGN_IN_WITH_GOOGLE: "SET_SIGN_IN_WITH_GOOGLE",
  SET_SIGN_IN_WITH_GOOGLE_START: "SET_SIGN_IN_WITH_GOOGLE_START",
  SET_CHANGE_PASSWORD: "SET_CHANGE_PASSWORD",
  SET_FORGET_PASSWORD: "SET_FORGET_PASSWORD",
  SET_LOGIN_PHONE_NUMBER: "SET_LOGIN_PHONE_NUMBER",
  SET_LOGIN_PHONE_NUMBER_START: "SET_LOGIN_PHONE_NUMBER_START",
  SET_LOGIN_PHONE_NUMBER_FAILED: "SET_LOGIN_PHONE_NUMBER_FAILED",
  SET_PHONE_IS_REGISTERED_STATUS: "SET_PHONE_IS_REGISTERED_STATUS",
  SET_SIGN_IN_WITH_APPLE: "SET_SIGN_IN_WITH_APPLE",
  SET_SIGN_IN_WITH_FACEBOOK: "SET_SIGN_IN_WITH_FACEBOOK",
  UPDATE_ACCESS_TOKEN: "UPDATE_ACCESS_TOKEN",
  SET_SUBSCRIBE_TO_COMING_SOON: "SET_SUBSCRIBE_TO_COMING_SOON",
  GET_USER_DETAIL: "GET_USER_DETAIL",
  GET_USER_DETAIL_START: "GET_USER_DETAIL_START",
  GET_USER_DETAIL_FAILED: "GET_USER_DETAIL_FAILED",
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_USERS_START: "GET_ALL_USERS_START",
  GET_ALL_USERS_FAILED: "GET_ALL_USERS_FAILED",
  GET_MAP_EVENTS: "GET_MAP_EVENTS",
  GET_MAP_EVENTS_START: "GET_MAP_EVENTS_START",
  GET_MAP_EVENTS_FAILED: "GET_MAP_EVENTS_FAILED",
  UPDATE_USER_DETAIL: "UPDATE_USER_DETAIL",
  UPDATE_USER_DETAIL_START: "UPDATE_USER_DETAIL_START",
  UPDATE_USER_DETAIL_FAILED: "UPDATE_USER_DETAIL_FAILED",
  UPDATE_USER_IMAGE: "UPDATE_USER_IMAGE",
  UPDATE_USER_IMAGE_START: "UPDATE_USER_IMAGE_START",
  UPDATE_USER_IMAGE_FAILED: "UPDATE_USER_IMAGE_FAILED",
  UPDATE_USER_LOCATION: "UPDATE_USER_LOCATION",
  UPDATE_USER_LOCATION_START: "UPDATE_USER_LOCATION_START",
  UPDATE_USER_LOCATION_FAILED: "UPDATE_USER_LOCATION_FAILED",
  REFRESH_USER_TOKEN: "REFRESH_USER_TOKEN",
  REFRESH_USER_TOKEN_START: "REFRESH_USER_TOKEN_START",
  REFRESH_USER_TOKEN_FAILED: "REFRESH_USER_TOKEN_FAILED",
  LOADING: "LOADING",
  LOGOUT: "LOGOUT",
  SWITCH_ACCOUNT: "SWITCH_ACCOUNT",
  START: "START",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  SET_USER_STATUS: "SET_USER_STATUS",
  SET_USER_STATUS_START: "SET_USER_STATUS_START",
  SET_USER_STATUS_FAILED: "SET_USER_STATUS_FAILED",
  SET_UPLOAD_MODAL: "SET_UPLOAD_MODAL",
  SET_PASSWORD_MESSAGE: "SET_PASSWORD_MESSAGE",
  SET_UPLOAD_MODAL_FAILED: "SET_UPLOAD_MODAL_FAILED",
  UPDATE_ACCESS_TOKEN_FAILED: "UPDATE_ACCESS_TOKEN_FAILED",
  SHOW_SEARCH_BAR: "SHOW_SEARCH_BAR",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESSFUL: "RESET_PASSWORD_SUCCESSFUL",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
  TOGGLE_THEME_BUTTON: "TOGGLE_THEME_BUTTON",
};
