import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { pen_icon } from "../../constants/images";
import "./verification.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthLoading,
  setCheckRegisteredPhoneNumberAction,
  setLoginPhoneNumberAction,
} from "../../redux/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { signInWithPhoneNumber } from "firebase/auth";

let currentOTPIndex = 0;

function Verification({
  handleSignUpStep,
  signup,
  verificationToken,
  handleloginStep,
  phoneNumber,
  auth,
}) {
  const isLoading = useSelector((state) => state.auth.phoneLoading);
  // const accessToken = useSelector((state) => state.auth.accessToken);
  // const phoneStatus = useSelector((state) => state.auth.phoneStatus);
  const phone = useSelector((state) => state.auth.phone);

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);

  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [, setConfirmed] = useState("confirm");
  const [, setActiveResend] = useState(false);
  const [, setResending] = useState(false);
  const [resendStatus, setResendStatus] = useState("Resend Code");
  const [, setTimeLeft] = useState(null);
  const [, setTargetTime] = useState(null);

  let resendTimerInterval;

  useEffect(() => {
    if (verifySuccess) {
      dispatch(
        setCheckRegisteredPhoneNumberAction(phone, handleloginStep, navigate)
      );
    }
    // eslint-disable-next-line
  }, [verifySuccess]);

  const handleOnChange = ({ target }, index) => {
    const { value } = target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);
    setOtp(newOTP);

    if (newOTP?.includes("", 0)) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  const handleVerification = async () => {
    dispatch(setAuthLoading(true));
    if (signup) {
      handleSignUpStep(3);
    }
    let newOtpValue = otp.join("");
    dispatch(
      setLoginPhoneNumberAction(
        {
          token: verificationToken,
          code: newOtpValue,
        },
        setVerifySuccess
      )
    );
    dispatch(setAuthLoading(false));
  };

  const handleOnKeyDown = ({ key }, index) => {
    currentOTPIndex = index;
    if (key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  };

  const calculateTimeLeft = (finalTime) => {
    const difference = finalTime - +new Date();

    if (difference >= 0) {
      setTimeLeft(Math.round(difference / 1000));
    } else {
      setTimeLeft(null);
      clearInterval(resendTimerInterval);
      setActiveResend(true);
    }
  };

  const triggerTimer = (targetTimeInSeconds = 30) => {
    setTargetTime(targetTimeInSeconds);
    setActiveResend(false);
    const finalTime = +new Date() + targetTimeInSeconds * 1000;
    resendTimerInterval = setInterval(() => {
      // eslint-disable-next-line
      return calculateTimeLeft(finalTime), 1000;
    });
  };

  const resendCode = async () => {
    setResending(true);
    try {
      setResendStatus("Resending...");
      const appVerifier = window.recaptchaVerifier;
      const formatPh = "+" + phoneNumber;

      const confirmation = await signInWithPhoneNumber(
        auth,
        formatPh,
        appVerifier
      );
      if (confirmation) {
        setConfirmed(confirmation);
        setResendStatus("Sent!");
      }
    } catch (error) {
      setResendStatus("Failed");
      alert("Resending Email Failed");
      // // Sentry.Native.captureException(error);
    }
    setResending(false);

    // hold on message
    setTimeout(() => {
      setResendStatus("Resend");
      setActiveResend(false);
      triggerTimer();
    }, 5000);
  };
  return (
    <div className="flex flex-col items-center gap-6">
      <div className="flex flex-col items-center gap-1">
        <h4 className=" text-center text-kdark3 text-xl md:text-2xl">
          Enter verification code
        </h4>
        <div className="flex gap-2">
          <div className="text-[#27272E] text-lg text-kbody6">
            We’ve sent a code to{" "}
            <span className="font-bold">
              +{phoneNumber.slice(0, 6) + "***" + phoneNumber.slice(9, 11)}
            </span>
          </div>
          <div>
            <p className="underline text-slightRed hover:cursor-pointer" onClick={() => handleSignUpStep(0)}>Change</p>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-2">
        {otp.map((_, index) => {
          return (
            <React.Fragment key={index}>
              <input
                ref={index === activeOTPIndex ? inputRef : null}
                type="number"
                className="w-14 h-14 border rounded-xl bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-[#D0D5DD] focus:border-[#F04438] focus:text-gray-700 text-gray-400 transition"
                onChange={handleOnChange}
                onKeyDown={(e) => handleOnKeyDown(e, index)}
                value={otp[index]}
              />
            </React.Fragment>
          );
        })}
      </div>
      <div
        className={`flex justify-center items-center w-full h-10 rounded-lg  ${
          disable ? "bg-gray-200 text-[#525050]" : "bg-[#F04438] text-white"
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <button
            type="submit"
            disabled={disable}
            onClick={handleVerification}
            className="w-full h-full border-none cursor-pointer outline-none"
          >
            Continue
          </button>
        )}
      </div>
      <p className="cursor-pointer" onClick={resendCode}>
        Expires in 00:45 <span className="text-[#F04438]"> {resendStatus}</span>
      </p>
    </div>
  );
}

export default Verification;
