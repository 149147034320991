import React from 'react'

function InputField({label,name,value,placeholder,changeValue}) {
  return (
    <>
      <label className='text-base font-medium'>{label}</label>
      <div className="flex w-full h-10 border border-[#CBD5E0] justify-between rounded-lg items-center ">
        <input name={name} className='w-full h-full border-none outline-none px-[22px] rounded-lg' value={value} placeholder={placeholder} onChange={changeValue} />
      </div>
    </>
  )
}

export default InputField