import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GrLocation } from "react-icons/gr";

const GooglePlace = ({ propsValue }) => {
  const [value] = useState(null);
  const API_KEY = "AIzaSyBvsPfW6EzKwgkLFsn9wQMg07ms8iFzhls";

  const handlePlaceSelect = (place) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${place.label}&key=${API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          propsValue({ latitude: lat, longitude: lng }, place.label);
        } else {
          console.error(
            "Unable to retrieve coordinates for the selected place."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const CustomMenuList = (props) => {
    console.log(props);
    return (
      <div className="my-2">
        {props &&
          props?.options?.map((item) => (
            <div
              onChange={handlePlaceSelect}
              className="flex items-start mb-2 gap-[8px]"
            >
              <GrLocation className="bg-[#02023414] rounded-[50%] w-[44px] h-[44px] text-[#1c2024] p-3" />
              <div className="">
                <h4 className="text-black text-[16px] font-[510px]">
                  {item?.label}
                </h4>
              </div>
            </div>
          ))}
      </div>
    );
  };
  const CustomInput = (props) => {
    console.log(props);
    return <div>{/* <input value={}/> */}</div>;
  };
  return (
    <div>
      <GooglePlacesAutocomplete
        className="bg-red-400"
        apiKey="AIzaSyBvsPfW6EzKwgkLFsn9wQMg07ms8iFzhls"
        nearbyPlacesAPI="GooglePlacesSearch"
        enablePoweredByContainer={false}
        fetchDetails={true}
        selectProps={{
          // components: {
          //   Menu: CustomMenuList, // Replace default menu with custom menu
          // },
          classNamePrefix: "tailwind",
          value,
          onChange: handlePlaceSelect,
          styles: {
            control: (provided) => ({
              ...provided,
              backgroundColor: "#05055806",
              borderRadius: "31px",
              border: "1px solid #02023414",
              boxShadow: "none",
              padding: "5px",
              fontSize: "14px",
            }),
          },
        }}
      />
    </div>
  );
};

export default GooglePlace;
